import React, { FC, useEffect, useState } from "react"
import LoginHeader from "@/component/login/header"
import { Form, Input, Button, Checkbox } from "antd"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Style from "@/style/view/login.module.less"
import { LoginPassport, GetInfo } from "@/request/api"
import { useAppDispatch } from "@/store/hook"
import { changeLang, changeLoginFetch } from "@/store/reducers/commonSlice"
import useInfo from "@/hook/useInfo"

export interface LoginStepType {
    callback?: Function
}

const LoginStepOne: FC<LoginStepType> = (props) => {

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const { messages, formatMessage } = useIntl()

    const { fetchPassed } = useInfo()

    const { callback } = props

    const [form] = Form.useForm()

    const [loading, setLoading] = useState<boolean>(false)

    const rememberMe = (isRemember: boolean, username?: string, password?: string) => {
        if (isRemember) {
            username && window.localStorage.setItem('username', username)
            password && window.localStorage.setItem('password', password)
        } else {
            window.localStorage.removeItem('username')
            window.localStorage.removeItem('password')
        }
    }

    const next = () => {
        form.validateFields().then((values: any) => {
            loginPassport(values)
            callback && callback(values)
        })
    }

    const loginPassport = (values: any) => {
        setLoading(true)
        const { remember_me, username, password } = values
        LoginPassport({ username, password }).then((res: any) => {
            sessionStorage.setItem('token', res.data.type + ' ' + res.data.token)
            fetchData()
            rememberMe(remember_me, username, password)
        }).catch(() => setLoading(false))
    }

    const fetchData = () => {
        GetInfo().then((res: any) => {
            if (res?.data?.locale) {
                dispatch(changeLang(res?.data?.locale))
            }
            fetchPassed().then((res: any) => {
                setLoading(false)
                dispatch(changeLoginFetch(true))
                res ? navigate('/app/portfolios') : navigate('/app/verification')
            }).catch(() => setLoading(false))
        }).catch(() => setLoading(false))
    }

    useEffect(() => {
        const { username, password } = window.localStorage
        if (username && password) {
            form.setFieldsValue({
                username, password,
                remember_me: true
            })
        }
    }, [])

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader title={ 'Login_title' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >

                        <Form.Item
                            label={ messages['email'] }
                            name={ 'username' }
                            rules={ [
                                {
                                    required: true,
                                },
                                {
                                    type: 'email',
                                    message: messages['not_valid_email'] as string,
                                }
                            ] }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={ messages['password'] }
                            name={ 'password' }
                            rules={ [{ required: true }] }
                        >
                            <Input.Password/>
                        </Form.Item>
                        <div className={ Style.rememberMe }>
                            <Form.Item
                                name="remember_me"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>
                                    { messages['remember_me'] }
                                </Checkbox>
                            </Form.Item>
                        </div>

                        <div className={ 'M-btn-warp' }>
                            <Button
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                loading={ loading }
                            >
                                { messages['Log_in'] }
                            </Button>
                        </div>

                    </Form>
                </div>
            </div>
            <div className={ Style.loginTipWrap }>
                <p onClick={ () => navigate('/resetPassword') }
                   className={ `${ Style.tips } mar-b-10 pointer text-primary` }>
                    { messages['forgot_password'] }
                </p>
                <p className={ `${ Style.tips_pa } ${ Style.tips }` }>
                    {
                        formatMessage(
                            { id: 'not_account_tip' }, {
                                sign_up:
                                    <span onClick={ () => navigate('/register') } className={ 'pointer text-primary' }>
                                        { messages['sign_up'] as string }
                                    </span>
                            })
                    }
                </p>
            </div>
        </div>
    )
}


export default LoginStepOne
