import { get, post, put, baseURL } from './axios'
import * as ApiType from './type'
// import { imagePreview } from "@/config"

//https://user.mottogam.com/api/attachments/e2928694232348f8b9d2e8002c5882e2?action=download
export const GetConfigs = () => get(`/configs`) //获取配置
export const GetCountry = () => get(`/country/${ 0 }`) //获取国家地区

export const GetAttachmentCategory = () => get(`/attachment/category`) //获取附件分类
export const UploadAddress = `${ baseURL }/attachments/upload` //附件上传
//export const AttachmentDownload = (attachment: string) => get(`/attachments/${ attachment }?action=downloa`) //下载附件

export const GetLang = () => get(`/languages`) //获取语言列表
export const UpdateLang = (data: { locale: string }) => post(`/passport/locale/update`, data) //修改语言'

// export const GetCaptchaCategory = () => get(`/captcha/category`) //获取验证码分类
// export const GetCaptchaType = () => get(`/captcha/types`) //获取验证码类型
export const SendCaptcha = (data: ApiType.SendCaptcha) => post(`/passport/captcha/send`, data) //发送验证码
export const CheckCaptcha = (data:ApiType.CheckCaptcha ) => post(`/passport/captcha/check`, data) //校验验证码

export const GetIdentTypes = () => get(`/user/identification-types`) //获取证件类型

export const RegisterPassport = (data: ApiType.RegisterType) => post(`/passport/register`, data) //注册
export const LoginPassport = (data: ApiType.LoginType) => post(`/passport/login`, data) //登录
export const LogoutPassport = () => get(`/passport/logout`) //退出登录
export const UpdatePassword = (data: ApiType.UpdatePassword) => post(`/passport/password/update`, data) //修改密码
export const ResetPassword = (data: ApiType.ResetPassword) => post(`/passport/password/reset`, data) //重置密码

export const UpdateEmail = (data: { email: string, captcha: string }) => post(`/passport/email/update`, data) //修改邮箱
export const CheckEmail = (data: { email: string }) => post(`/passport/email/check`, data) //检测邮箱

export const GetInfo = () => get(`/passport`) //获取账号信息
export const GetInfoDetail = () => get(`/passport/information`) //获取个人资料
export const UpdateInfoDetail = (data: ApiType.InfoDetail) => post(`/passport/information`, data) //修改个人资料

export const GetIdent = () => get(`/passport/identification`) //认证信息
export const UpdateIdent = (data: ApiType.UpdateIdent ) => post(`/passport/identification`, data) //认证信息（身份证，驾照）修改
export const GetIdentProgress = () => get(`/passport/identification/progress`) //认证信息进度 (Completed, null)

//qualification/progress questionnaire/progress

export const GetQualification = () => get(`/passport/qualification`) //资格信息
export const UpdateQualification = (data: { image_funds: string }) => post(`/passport/qualification`, data) //资格信息修改
export const GetQualificationProgress = () => get(`/passport/qualification/progress`) //资格信息进度

export const GetLegalEntity = () => get(`/passport/legal-entity`) //法人信息
export const UpdateLegal = (data: ApiType.AddLegal) => post(`/passport/legal-entity`, data) //法人信息增加/修改

export const GetPortfolios = (data: ApiType.dataList) => get(`/portfolios`, data) //portfolios列表
export const GetPortfolioDetail = (id: ApiType.dataList) => get(`/portfolios/${ id }`) //portfolios详情

export const GetOpportunity = (data: ApiType.dataList) => get(`/opportunity`, data) //opportunity列表

export const GetNews = (data: ApiType.dataList) => get(`/news`, data) //news列表
export const GetNewsDetail = (id: string) => get(`/news/${ id }`) //news详情

export const GetDocument = (data: ApiType.dataList) => get(`/attachments`, data) //Document

export const GetQuestionnaire= () => get(`/questionnaires`) //问卷列表
export const GetPassportQuesList = () => get(`/passport/questionnaire`) //个人提交的问卷列表
export const SubQuestionnaire = (data: any) => post(`/passport/questionnaire`, data) //提交问卷
export const GetQuestionnaireProgress = () => get(`/passport/questionnaire/progress`) //问卷进度
