import React, { FC } from "react"
import { useIntl } from "react-intl"
import Style from '@/style/componet/card.module.less'

interface CardsType {
    title: string
    btnText?: boolean | string
    editClick?: Function
}

const Cards: FC<CardsType> = (props) => {

    const { messages } = useIntl()

    const { title, btnText, children, editClick } = props

    const onClickHandle = () => {
        editClick && editClick()
    }

    const renderBtn = () => {
        if (!btnText) return null
        switch (btnText) {
            case true:
                return <span onClick={ () => onClickHandle() } className={ 'fr pointer text-primary font-size-16' }>{ messages['Edit'] }</span>
            default:
                return <span onClick={ () => onClickHandle() } className={ 'fr pointer text-primary font-size-16' }>{ messages[btnText] }</span>
        }
    }

    return (
        <div className={ Style.cards }>
            <header className={ 'clearfix' }>
                <span className={ 'fl font-weight-500 font-size-22' }>{ messages[title] }</span>
                {
                    renderBtn()
                }
            </header>
            <div className={ Style.main }>
                {
                    children
                }
            </div>
        </div>
    )
}

export default Cards

Cards.defaultProps = {
    btnText: true
}
