import React, { FC, useRef, useState } from "react"
import Cards from "@/component/common/card"
import { useIntl } from "react-intl"
import { Descriptions, message, Modal } from "antd"
import InfoForm from "@/component/verificationId/InfoForm"
import { RefFun } from "@/type"
import { useAppSelector } from "@/store/hook"
import useCountry from "@/hook/useCountry"
import { UpdateInfoDetail } from "@/request/api"
import useInfo from "@/hook/useInfo"

const Information: FC = () => {

    const { messages } = useIntl()

    const { info, infoDetail, identProgress } = useAppSelector(state => state.info)

    const { fetchCountry } = useCountry()

    const { fetchInfo } = useInfo()

    const InfoFormRef = useRef<RefFun>()

    const [openInfoForm, setOpenInfoForm] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)

    const infoFormBtnHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "cancel":
                //InfoFormRef?.current?.reset()
                setOpenInfoForm(false)
                break
            case "confirm":
                InfoFormRef?.current?.getValue()
                break
        }
    }

    const infoFormCallBack = (values: any) => {
        setLoading(true)
        const { address, country_id, name, year, month, day, postcode, tax_id, tax_residence } = values
        UpdateInfoDetail({
            address,
            country_id,
            postcode, tax_id,
            tax_residence,
            name,
            birthday: `${ year }-${ month }-${ day }`
        }).then(() => {
            fetchInfo()
            setLoading(false)
            message.success(messages['submitted_successfully'])
            setOpenInfoForm(false)
        }).catch(() => setLoading(false))
    }

    const editClick = () => {
        setOpenInfoForm(true)
    }

    return (
        <div>
            <Cards title={ 'Personal_Information' } btnText={ identProgress === 'Completed' }  editClick={ editClick }>
                <Descriptions className={ 'M-des' } layout="vertical">
                    <Descriptions.Item label={ messages['FULL_NAME'] }>{ infoDetail?.name }</Descriptions.Item>
                    <Descriptions.Item span={ 2 } label={ messages['DAY_OF_BIRTH'] }>{ infoDetail?.birthday }</Descriptions.Item>
                    <Descriptions.Item label={ messages['PHONE'] }>+{ info?.mobile_prefix } { info?.mobile}</Descriptions.Item>
                    <Descriptions.Item label={ messages['COUNTRY'] }>{ fetchCountry(infoDetail?.country_id)?.title }</Descriptions.Item>
                    <Descriptions.Item label={ messages['POSTAL_CODE'] }>{ infoDetail?.postcode }</Descriptions.Item>
                    <Descriptions.Item span={ 3 } label={ messages['ADDRESS'] }>{ infoDetail?.address }</Descriptions.Item>
                    <Descriptions.Item label={ messages['TAX_RESIDENCE'] }>{ fetchCountry(infoDetail?.tax_residence)?.title }</Descriptions.Item>
                    <Descriptions.Item label={ messages['TAX_ID'] }>{ infoDetail?.tax_id }</Descriptions.Item>
                </Descriptions>
            </Cards>
            <Modal
                className={ 'M-modal' }
                title={ messages['Edit_Personal_Information'] }
                open={ openInfoForm }
                onCancel={ () => infoFormBtnHandle('cancel') }
                okText={ messages['Save'] }
                onOk={ () => infoFormBtnHandle('confirm')}
                confirmLoading={ loading }
            >
                <InfoForm ref={ InfoFormRef } callback={ infoFormCallBack } showFullName={ true }/>
            </Modal>
        </div>
    )
}

export default Information
