// 个人信息
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import {
    GetInfo,
    GetInfoDetail,
    GetIdent,
    GetIdentProgress,
    GetQualification,
    GetPassportQuesList,
    GetQualificationProgress,
    GetQuestionnaireProgress
} from "@/request/api"
import { stateType } from "@/tool/filiter"

interface CounterState {
    value: number,
    info: any,
    infoDetail: any,
    ident: any,
    identProgress: any,
    qualificationProgress: stateType,
    qualification: any,
    passportQuesList: [],
}

const initialState: CounterState = {
    value: 0,
    info: '',
    infoDetail: '',
    ident: '',
    identProgress: '',
    qualificationProgress: null,
    qualification: '',
    passportQuesList: [],
}

export const getInfo = createAsyncThunk( // 获取个人信息
    'info/getInfo',
    async () => {
        return await GetInfo()
    }
)

export const getInfoDetail = createAsyncThunk( // 获取个人信息详情
    'info/GetInfoDetail',
    async () => {
        return await GetInfoDetail()
    }
)

export const getIdent = createAsyncThunk( // 获取个人认证信息
    'info/GetIdent',
    async () => {
        return await GetIdent()
    }
)

export const getIdentProgress = createAsyncThunk( // 获取个人认证信息进度
    'info/getIdentProgress',
    async () => {
        return await GetIdentProgress()
    }
)

export const getQualification = createAsyncThunk( // 获取资格信息
    'info/getQualification',
    async () => {
        return await GetQualification()
    }
)

export const getQualificationProgress = createAsyncThunk( // 获取资格信息进度
    'info/getQualificationProgress',
    async () => {
        return await GetQualificationProgress()
    }
)

export const getPassportQuesList = createAsyncThunk( // 获取个人提交的问卷列表
    'info/getPassportQuesList',
    async () => {
        return await GetPassportQuesList()
    }
)

export const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        increment: state => {
            state.value += 1
        },
        decrement: state => {
            state.value -= 1
        },
        // action传参
        incrementByAmount: (state, action: PayloadAction<any>) => {
            state.value += action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInfo.pending, (state) => {

            })
            .addCase(getInfo.fulfilled, (state, action: PayloadAction<any>) => {
                state.info = action.payload.data
            })
            .addCase(getInfo.rejected, (state) => {
                // state.info = state.payload.data
            })
            .addCase(getInfoDetail.fulfilled, (state, action: PayloadAction<any>) => {
                state.infoDetail = action.payload.data
            })
            .addCase(getIdent.fulfilled, (state, action: PayloadAction<any>) => {
                state.ident = action.payload.data
            })
            .addCase(getIdentProgress.fulfilled, (state, action: PayloadAction<any>) => {
                state.identProgress = action.payload.data
            })
            .addCase(getQualification.fulfilled, (state, action: PayloadAction<any>) => {
                state.qualification = action.payload.data
            })
            .addCase(getQualificationProgress.fulfilled, (state, action: PayloadAction<any>) => {
                state.qualificationProgress = action.payload.data
            })
            .addCase(getPassportQuesList.fulfilled, (state, action: PayloadAction<any>) => {
                state.passportQuesList = action.payload.data
            })
    },
})

export const { increment, decrement, incrementByAmount } = infoSlice.actions

export const selectCount = (state: RootState) => state.info

export default infoSlice.reducer
