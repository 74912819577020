import React, { FC, useEffect, useState } from "react"
import { Col, Pagination, Row } from "antd"
import { useIntl } from "react-intl"
import { GetDocument } from "@/request/api"
import { useAppSelector } from "@/store/hook"
import moment from "moment"
import { download } from "@/tool"
import SpinCom from "@/component/common/SpinCom"
import NoData from "@/component/common/noData"

const Documents: FC = () => {//Documents

    const { messages } = useIntl()

    const { currentLang } = useAppSelector(state => state.common)

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [dataList, setDataList] = useState<[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getDocument = (page_: number = page, pageSize_: number = pageSize) => {
        setLoading(true)
        GetDocument({ page: page_, page_size: pageSize_ }).then((res: any) => {
            setLoading(false)
            setTotal(res?.data?.total)
            setDataList(res?.data?.data)
        }).catch(() => setLoading(false))
    }

    const pageChange = (page_: number, pageSize_: number) => {
        setPage(page_)
        setPageSize(pageSize_)
        getDocument(page_, pageSize_)
    }

    useEffect(() => {
        getDocument(1)
    }, [currentLang])

    return (
        <div>
            <div>
                <header className={ `page-title` }>{ messages['Documents'] }</header>
                <div className={ `table-wrap` }>
                    <header>
                        <Row>
                            <Col lg={ 6 } span={ 0 }>
                                <span className={ 'title' }>{ messages['Date'] }</span>
                            </Col>
                            <Col lg={ 15 } span={ 0 }>
                                <span className={ 'title' }>{ messages['Document_Type'] }</span>
                            </Col>
                            <Col lg={ 3 } span={ 0 }>
                                <span className={ 'title' }>{ messages['Action'] }</span>
                            </Col>
                        </Row>
                    </header>
                    <ul>
                        {
                            loading ? <SpinCom/> : dataList?.length ? dataList.map((item: any) =>
                                    <li key={ item?.id }>
                                        <Row>
                                            <Col className={ 'item' } lg={ 6 } span={ 24 }>
                                                <span
                                                    className={ 'font-weight-500' }>{ moment.unix(item?.created_at).format('YYYY-MM-DD HH:mm') }</span>
                                            </Col>
                                            <Col className={ 'item' } lg={ 15 } span={ 24 }>
                                                <span className={ 'font-weight-400' }>{ item?.category }</span>
                                            </Col>
                                            <Col className={ 'item action-wrap' } lg={ 3 } span={ 24 }>
                                                <span onClick={ () => download(item?.uuid) } className={ 'action' }>{ messages['Download'] }</span>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                                : <NoData/>
                        }
                    </ul>
                    <div className={ `M-pagination clearfix ${ dataList?.length ? 'show' : 'hide' }` }>
                        <div className={ 'fr' }>
                            <Pagination current={ page } total={ total } onChange={ pageChange } showSizeChanger/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documents
