import axios from 'axios'
import history from "../lib/history"
import { message } from 'antd'
import { langFilter } from "@/tool/filiter"

let showMsg = true

axios.defaults.timeout = 50000

//axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/api/' : 'https://hh.aj9.top/api'

export const baseURL = process.env.REACT_APP_PROXY || process.env.REACT_APP_SERVER // 设置了代理走代理 否则走REACT_APP_SERVER

axios.defaults.baseURL = baseURL

// console.log(process.env)

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(
    (config: any) => {
        const lang_ = window.localStorage.getItem('lang') || 'en'
        config.headers['x-api-locale'] = langFilter(lang_)
        // token验证
        if (sessionStorage.token) {
            config.headers["Authorization"] = `${ sessionStorage.token }`
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        //有token返回加上token
        if (response.headers['authorization']) {
            window.sessionStorage.setItem('token', response.headers['authorization'])
        }
        return response
    },
    error => {
        if (error?.response?.data?.code) {
            switch (error?.response?.data?.code) {
                case 401:
                case '401':
                    sessionStorage.removeItem('token')
                    history.push('/login')
                    if (showMsg) { // 防止多条“用户超时”
                        message.error(error?.response?.data?.message)
                        showMsg = false
                        setTimeout(() => {
                            showMsg = true
                        }, 5000)
                    }
                    break
                default:
                    message.error(error?.response?.data?.message)
            }
        }
        return Promise.reject(error.response) // 返回接口返回的错误信息
    }
)

export const get = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err?.data)
        })
    });
}

export const post = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify(params))
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const deletes = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err?.data)
        })
    })
}

export const put = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
