import React, { FC, useEffect, useState } from "react"
import LoginStepOne from "@/view/login/login/stepOne"
import LoginStepTwo from "@/view/login/login/stepTwo"
import useParamsLang from '@/hook/useParamsLang'

const Login: FC = () => {

    const { paramsChangeLang } = useParamsLang()

    const [step, setStep] = useState<number>(1)

    const stepOneCallback = (stepOneData: any) => {
        console.log(stepOneData)
        // setStep(2)
    }

    const stepTwoCallback = (stepTwoData: any) => {
        console.log(stepTwoData)
        setStep(3)
    }

    useEffect(() => {
        paramsChangeLang()
    }, [])

    const renderDom = () => {
        switch (step) {
            case 1:
                return <LoginStepOne callback={ stepOneCallback }/>
            case 2:
                return <LoginStepTwo callback={ stepTwoCallback } setStep={ setStep }/>
        }
    }

    return (
        <div>
            { renderDom() }
        </div>
    )
}

export default Login
