import React, { FC, useEffect, useState } from "react"
import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { useAppDispatch, useAppSelector } from "@/store/hook"
import { changeLang } from '@/store/reducers/commonSlice'

const LocaleLang: FC = () => {

    const dispatch = useAppDispatch()

    const { currentLang } = useAppSelector(state => state.common)

    const [menuItems, setMenuItems] = useState<MenuProps['items']>([])

    const items: MenuProps['items'] = [
        {
            key: 'en',
            label: (
                <div className={ 'localLang' }>
                    <img src={ require('@/static/img/country/en.svg').default } alt="en"/>
                    <span>English</span>
                </div>
            )
        },
        {
            key: 'zh',
            label: (
                <div className={ 'localLang' }>
                    <img src={ require('@/static/img/country/zh.svg').default } alt="zh"/>
                    <span>简体中文</span>
                </div>

            )
        },
        {
            key: 'ja',
            label: (
                <div className={ 'localLang' }>
                    <img src={ require('@/static/img/country/ja.svg').default } alt="ja"/>
                    <span>日本語</span>
                </div>
            )
        }
    ]

    const onClick: MenuProps['onClick'] = ({ key }) => {
        dispatch(changeLang(key))
    }

    useEffect(() => {
        if (currentLang) {
            const menuList = items.filter((item) => item?.key !== currentLang)
            setMenuItems(menuList)
        }
    }, [currentLang])

    return (
        <div>
            <Dropdown menu={ { items: menuItems, onClick } } arrow trigger={ ['click', 'hover'] }>
                <div className={ 'localLang pointer' }>
                    <img className={ 'border' } src={ require(`@/static/img/country/${ currentLang }.svg`).default }
                         alt="lang"/>
                </div>
            </Dropdown>
        </div>
    )
}

export default LocaleLang
