import { useAppSelector } from "@/store/hook";

const useLangTitle = () => {

    const { langList } = useAppSelector(state => state.common)

    const fetchLangTitle = (lang: string) => {
        if (langList?.length) {
            let obj: any = langList.filter((item: any) => item?.name === lang)[0]
            return obj?.title
        } else {
            return lang || null
        }
    }

    return { fetchLangTitle }
}

export default useLangTitle
