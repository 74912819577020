import React, { ReactNode, ForwardRefRenderFunction, useImperativeHandle, forwardRef, useState, useEffect } from "react"
import { Form, Select } from "antd"
import { useIntl } from "react-intl"
import UploadAntd from "@/component/common/uoloadAntd"
import { useAppSelector } from "@/store/hook"
import { useNavigate } from "react-router-dom"

export interface FormComType {
    setImageFront?: Function,
    setImageBack?: Function
    callback?: Function,
    record?: any
}

const { Option } = Select

const UploadDoc: ForwardRefRenderFunction<ReactNode, FormComType> = (props, ref) => {

    const { callback } = props

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { identTypes } = useAppSelector(state => state.common) // 证件类型

    const { ident } = useAppSelector(state => state.info) // 认证信息

    const [category, setCategory] = useState<string>('identity-card')

    const [imageFront, setImageFront] = useState<string>('')
    const [imageBack, setImageBack] = useState<string>('')

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback({ ...values, imageFront, imageBack })
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    const onChange = (value: string) => {
        setCategory(value)
    }

    useEffect(() => {
        if (ident?.id) {
            form.setFieldsValue({
                type: ident?.type || ''
            })
        }
    },[ident])

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >
            <Form.Item
                label={ messages['Certificate_Type'] }
                name='type'
                rules={ [
                    { required: true }
                ] }
            >
                <Select onChange={ onChange }>
                    {
                        identTypes?.length ? identTypes.map((item: any) =>
                            <Option key={ item?.id } value={ item?.name }>{ item?.title }</Option>
                        ) : null
                    }
                </Select>
            </Form.Item>
            <Form.Item
                className={ 'no-border-b' }
                label={ messages['Front'] }
            >
                <UploadAntd image={ ident?.image_front } category={ category } callback={ setImageFront }/>
            </Form.Item>
            <Form.Item
                className={ 'no-border-b' }
                label={ messages['Back'] }
            >
                <UploadAntd image={ ident?.image_back } category={ category } callback={ setImageBack }/>
            </Form.Item>
        </Form>
    )
}

export default forwardRef(UploadDoc)
