import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Button, message, Modal, Space } from "antd"
import UploadAntd from "@/component/common/uoloadAntd"
import { useNavigate } from "react-router-dom"
import BackBtn from "@/component/common/backBtn"
import { UpdateQualification } from "@/request/api"
import { useAppDispatch, useAppSelector } from "@/store/hook"
import { getQualification } from "@/store/reducers/infoSlice"

const Qualification: FC = () => {

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const { messages } = useIntl()

    const { qualification } = useAppSelector(state => state.info)

    const [openUpload, setOpenUpload] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [funds, setFunds] = useState<string>('')

    const btnHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "confirm":
                submit()
                break
            case "cancel":
                setOpenUpload(false)
                break
        }
    }

    const submit = () => {
        if (!funds) {
            message.warning(messages['Please_select_file'])
            return false
        }
        setLoading(true)
        UpdateQualification({
            image_funds: funds
        }).then((_res: any) => {
            dispatch(getQualification())
            message.success(messages['submitted_successfully'])
            setOpenUpload(false)
            navigate('/app/account')
        })
    }

    useEffect(() => {
        if (qualification) {
            navigate(-1)
        }
    }, [qualification])

    return (
        <div>
            <header className={ 'page-title' }>
                <span>{ messages['Professional_Qualification'] }</span>
                <BackBtn title={ 'Back_to_My_Account' } backPath={ '/app/account' }/>
            </header>
            <div className={ 'block-line' }>
                <header className={ 'font-weight-500 font-size-22 mar-b-26' }>{ messages['Proof_of_Funds'] }</header>
                <p className={ 'mar-b-26 font-size-16 line-24' }>{ messages['Proof_of_Funds_tip'] }</p>
                <header className={ 'font-weight-500 font-size-16 mar-b-26' }>{ messages['Documents_to_be_submitted'] }</header>
                <p className={ 'p-items line-24' }>{ messages['Documents_tip1'] }</p>
                <p className={ 'p-items line-24' }>{ messages['Documents_tip2'] }</p>
                <p className={ 'p-items line-24' }>{ messages['Documents_tip3'] }</p>
                <p className={ 'p-items line-24 mar-b-40' }>{ messages['Data_format'] }: GIF, JPG, PNG, PDF</p>
                <div className={ `btn-group` }>
                    <Space>
                        <Button
                            className={ 'M-btn-42' }
                            onClick={ () => navigate(-1) }
                        >
                            { messages['back'] }
                        </Button>
                        <Button
                            className={ 'M-btn-42 min-width-180' }
                            type='primary'
                            onClick={ () => setOpenUpload(true) }
                        >
                            { messages['Upload_File'] }
                        </Button>
                    </Space>
                </div>
                <Modal
                    className={ 'M-modal' }
                    title={ messages['Upload_Proof_of_Funds'] }
                    open={ openUpload }
                    onCancel={ () => setOpenUpload(false) }
                    footer={
                        <Space>
                            <Button onClick={ () => btnHandle("cancel") } ghost>{ messages['Cancel'] }</Button>
                            <Button loading={ loading } onClick={ () => btnHandle('confirm') } type='primary'>{ messages['Submit'] }</Button>
                        </Space>
                    }
                >
                    <UploadAntd category='proof-funds' callback={ setFunds }/>
                </Modal>
            </div>
        </div>
    )
}

export default Qualification
