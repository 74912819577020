import React, { FC } from "react"
import Style from '@/style/view/login.module.less'
import { useIntl } from "react-intl"
import LocaleLang from "@/component/common/locaeLang"

interface LoginHeaderType {
    step?: 1 | 2 | 3,
    title?: string
}

const LoginHeader: FC<LoginHeaderType> = (props) => {

    const { messages } = useIntl()

    const { step, title } = props

    return (
        <div>
            <header className={ Style.header }>
                <img className={ 'logo' } src={ require('@/static/img/logo.png').default } alt="LOGO"/>
                <div className={ Style.localLang }>
                    <LocaleLang/>
                </div>
                {
                    step ?
                        <ul className={ `${ Style.line } clearfix` }>
                            <li className={ step === 1 ? `${ Style.active } fl` : 'fl' }/>
                            <li className={ step === 2 ? `${ Style.active } fl` : 'fl' }/>
                            <li className={ step === 3 ? `${ Style.active } fl` : 'fl' }/>
                        </ul> : <div style={ { paddingTop: 20} }/>
                }
                <span className={ Style.title }>{ messages[title as string] }</span>
            </header>
        </div>
    )
}

export default LoginHeader
