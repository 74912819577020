import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useIntl } from "react-intl"
import Style from '@/style/view/login.module.less'
import LoginHeader from "@/component/login/header"
import { Button, Form, Input } from "antd"
import GetCodeButton from "@/component/common/getCodeButton"

interface LoginStepTwoType {
    callback?: Function,
    setStep: Function
}

const LoginStepTwo: FC<LoginStepTwoType> = (props) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const navigate = useNavigate()

    const { callback, setStep } = props

    const next = () => {
        form.validateFields().then((values: any) => {
            callback && callback(values)
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader title={ 'Login_title' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >
                        <div className={ `${ Style.tips2 } ${ Style.verificationTip }` }>{ messages['verification_tip'] }</div>
                        <div className={ 'getCodeItem' }>
                            <Form.Item
                                label={ messages['verification_code'] }
                                name={ 'verification_code' }
                                rules={ [{ required: true }] }
                            >
                                <Input placeholder={ messages['please_enter_verification'] as string }/>
                            </Form.Item>
                            <div className={ 'getCodeButton' }>
                                <GetCodeButton address={ 'test@qq.com' } type={ 'email' } category={ 'register' }/>
                            </div>
                        </div>
                        <div className={ 'M-btn-warp' }>
                            <Button
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                            >
                                { messages['verify'] }
                            </Button>
                        </div>
                        <div className={ `M-btn-warp ${ Style.backWrap }` }>
                            <Button
                                onClick={ () => setStep(1) }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                ghost
                            >
                                { messages['back'] }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default LoginStepTwo
