import React, { FC, useState } from "react"
import Style from '@/style/componet/mobile.module.less'
import { CloseOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"
import { menuFilter } from "@/component/common/navbar"
import routes, { routeType } from "@/router"
import { useNavigate } from "react-router-dom"
import { Modal } from "antd"
import Schedule from "@/component/schedule"
import { useAppSelector } from "@/store/hook"

interface MobileMenuType {
    setOpenMobileMenu: Function
}

const MobileMenu: FC<MobileMenuType> = (props) => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const { setOpenMobileMenu } = props

    const { infoDetail } = useAppSelector(state => state.info)

    const [openSchedule, setOpenSchedule] = useState<boolean>(false)

    const menuClick = (path: string, signOut?: boolean) => {
        setOpenMobileMenu(false)
        if (signOut) {
            localStorage.removeItem('token')
            navigate(path)
        } else {
            navigate(path)
        }
    }

    const scheduleCall = () => {
        setOpenMobileMenu(false)
        setOpenSchedule(true)
    }

    return (
        <div className={ Style.mobileMenu }>
            <header className={ 'clearfix' }>
                <img className={ 'fl logo_icon' } src={ require('@/static/img/logo.png').default } alt="Logo"/>
                <div onClick={ () => setOpenMobileMenu(false) } className={ `fr ${ Style.close } pointer` }>
                    <CloseOutlined style={ { fontSize: 18, color: '#777' } }/>
                </div>
            </header>
            <div className={ `${ Style.info } clearfix` }>
                <div className={ `user-icon ${ Style.userIconWrap } fl` }>
                    <img src={ require('@/static/img/user-solid.png').default } alt="icon"/>
                </div>
                <div className={ `${ Style.nameWrap } fl` }>
                    <div className={ Style.name }>{ infoDetail?.name }</div>
                    <div className={ Style.id }>
                        <span>{ messages['Customer_Id'] }:</span>
                        <i>{ infoDetail?.user_id }</i>
                    </div>
                </div>
            </div>
            <div>
                <ul className={ Style.menuList }>
                    {
                        menuFilter(routes).map((item: routeType) =>
                            <li
                                key={ item?.key }
                                onClick={ () => menuClick(item.key as string) }
                            >
                                <span> { messages[item?.label] }</span>
                            </li>)
                    }
                </ul>
                <ul className={ Style.menuList }>
                    <li onClick={ scheduleCall } className={ Style.schedule_a_call }>
                        <img src={ require('@/static/img/phone-dial.png').default } alt="call"/>
                        <span className={ 'text-primary' }>{ messages['schedule_a_call'] }</span>
                    </li>
                </ul>
                <ul className={ Style.menuList }>
                    <li onClick={ () => menuClick('/app/account') }>
                        <span>{ messages['My_Account'] }</span>
                    </li>
                    <li onClick={ () => menuClick('/app/documents') }>
                        <span>{ messages['Documents'] }</span>
                    </li>
                    <li onClick={ () => menuClick('/login', true) }>
                        <span>{ messages['Sign_Out'] }</span>
                    </li>
                </ul>
            </div>
            {/*Schedule call*/}
            <Modal
                open={ openSchedule }
                footer={ false }
                className={'M-modal'}
                width={ 1000 }
                onCancel={ () => setOpenSchedule(false) }
            >
                <Schedule/>
            </Modal>
        </div>
    )
}

export default MobileMenu
