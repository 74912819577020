import React, { FC, useState } from "react"
import { useIntl } from "react-intl"
import Style from '@/style/view/login.module.less'
import LoginHeader from "@/component/login/header"
import { Button, Form, Input } from "antd"
import { regular } from "@/tool/config"
import ErrorMessage from "@/component/common/errorMessage"
import { useNavigate } from "react-router-dom"

interface ResetPassStepTwoType {
    loading: boolean,
    callback?: Function
}

const ResetPassStepTwo: FC<ResetPassStepTwoType> = (props) => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback, loading } = props

    const [password, setPassword] = useState<string>('')

    const passChange = (e: any) => {
        setPassword(e.target.value)
    }

    const next = () => {
        form.validateFields().then((values: any) => {
            callback && callback(2, values)
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader title={ 'reset_password' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >

                        <Form.Item
                            label={ messages['new_password'] }
                            name='password'
                            rules={ [
                                { required: true },
                                { pattern: regular?.password, message: '' }
                            ] }
                        >
                            <Input.Password onChange={ passChange }/>
                        </Form.Item>

                        <Form.Item
                            label={ messages['confirm_password'] }
                            name='password_confirm'
                            rules={ [
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(messages['passwords_not_match'] as string))
                                    },
                                }),
                            ] }
                        >
                            <Input.Password/>
                        </Form.Item>

                        <ErrorMessage text={ password }/>

                        <div className={ `${ Style.tips2 } ${ Style.verificationTip }` }>{ messages['password_tip'] }</div>

                        <div className={ 'M-btn-warp' }>
                            <Button
                                loading={ loading }
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                            >
                                { messages['save_changes'] }
                            </Button>
                        </div>

                    </Form>
                </div>
            </div>
            <div className={ Style.loginTipWrap }>
                <p onClick={ () => navigate('/login') }
                   className={ `${ Style.tips } mar-b-10 pointer text-primary` }>
                    { messages['Return_to_login'] }
                </p>
            </div>
        </div>
    )
}

export default ResetPassStepTwo
