import { ReactNode } from 'react'
import * as com from './component'

export interface routeType {
    label: string
    path?: string
    key?: string
    component?: any,
    icon?: string | ReactNode
    hide?: boolean
    type?: 'operation' | 'jump'
    children?: routeType[],
    labelRender?: ReactNode
}

// home router
const routes: routeType[] = [
    {
        path: 'home',
        component: com.Home,
        key: '/app/home',
        label: 'home',
        hide: true,
    },
    {
        path: 'portfolios',
        component: com.Portfolios,
        key: '/app/portfolios',
        label: 'portfolios',
        hide: false,
    },
    {
        path: 'opportunities',
        component: com.Opportunities,
        key: '/app/opportunities',
        label: 'opportunities',
        hide: false,
    },
    {
        path: 'news',
        component: com.News,
        key: '/app/news',
        label: 'news',
        hide: false,
        children: [
            {
                path: 'news/:id',
                component: com.NewsDetail,
                key: '/app/news/detail',
                label: 'newsDetail',
                hide: true
            }
        ]
    },
    {
        path: 'account',
        component: com.Account,
        key: '/app/account',
        label: 'account',
        hide: true
    },
    {
        path: 'documents',
        component: com.Documents,
        key: '/app/documents',
        label: 'documents',
        hide: true
    },
    {
        path: 'verificationId',
        component: com.VerificationId,
        key: '/app/verificationId',
        label: 'verificationId',
        hide: true
    },
    {
        path: 'questionnaire',
        component: com.Questionnaire,
        key: '/app/questionnaire',
        label: 'questionnaire',
        hide: true
    },
    {
        path: 'qualification',
        component: com.Qualification,
        key: '/app/qualification',
        label: 'qualification',
        hide: true
    },
    {
        path: 'verification',
        component: com.Verification,
        key: '/app/verification',
        label: 'verification',
        hide: true
    }
]


export default routes
