import React, { FC } from "react"
import { Button, Col, Row } from "antd"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "@/store/hook"

const ValidationList: FC = () => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { passportQuesList, identProgress, qualification } = useAppSelector(state => state.info)

    const list = [
        {
            key: "ID_Verification",
            src: require('@/static/img/card-account-details-outline.png').default,
            btnTitle: "Verify_ID",
            path: "/app/verificationId",
            state: identProgress,
        },
        {
            key: "Investor_Questionnaire",
            src: require('@/static/img/clipboard-edit-outline.png').default,
            btnTitle: "Fill_Questionnaire",
            path: "/app/questionnaire",
            state: passportQuesList?.length ? 'Completed' : null,
        },
        {
            key: "Professional_Qualification",
            src: require('@/static/img/file-cabinet.png').default,
            btnTitle: "Start_Qualification",
            path: "/app/qualification",
            state: qualification ? 'Completed' : null
        }
    ]

    const stateButton = (state: string, item: any) => {
        switch (state) {
            case "Pending":
                return <span style={ { marginTop: 6 } } className={ 'btn-pending fr' }>{ messages['Applying'] }</span>
            case "Processing":
                return <span style={ { marginTop: 6 } } className={ 'btn-pending fr' }>{ messages['Processing'] }</span>
            case "Closed":
                return (
                  <div>
                      <Button
                          className={ 'M-btn-42' }
                          type='primary'
                          onClick={ () => navigate(item?.path) }
                      > { messages[item?.btnTitle] }
                      </Button>
                  </div>
                )
            case "Completed":
                if (item?.key === 'ID_Verification') {
                    return (
                        <span style={ { marginTop: 6 } } className={ 'btn-complete fr' }>{ messages['Approved'] }</span>
                    )
                } else {
                    return (
                        <span style={ { marginTop: 6 } } className={ 'btn-complete fr' }>{ messages['Completed'] }</span>
                    )
                }
            default:
                return (
                    <Button
                        className={ 'M-btn-42' }
                        type='primary'
                        onClick={ () => navigate(item?.path) }
                    > { messages[item?.btnTitle] }
                    </Button>
                )
        }
    }

    return (
        <ul className={ 'lists-account' }>
            {
                list.map((item: any) =>
                    <li key={ item?.key }>
                        <Row>
                            <Col lg={ 12 } span={ 24 }>
                                <div className={ 'img-wrap' }>
                                    <img src={ item?.src } alt="ID Verification"/>
                                </div>
                                <span className={ 'titles' }>{ messages[item.key] }</span>
                            </Col>
                            <Col lg={ 12 } span={ 24 }>
                                <div className={ 'btn-wraps-account clearfix' }>
                                    { stateButton(item?.state, item) }
                                </div>
                            </Col>
                        </Row>
                    </li>
                )
            }
        </ul>
    )
}

export default ValidationList
