import React, { FC } from "react"
import { Spin } from 'antd'

interface SpinComType {
    delay?: number
}

const SpinCom: FC<SpinComType> = (props) => {

    const { delay } = props

    return (
        <div style={ { padding: 30, textAlign: 'center' } }>
            <Spin delay={ delay }/>
        </div>
    )
}

export default SpinCom
