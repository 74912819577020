import React, { FC, useState } from "react"
import { message } from "antd"
import { useIntl } from "react-intl"
import { SendCaptcha } from '@/request/api'
import { CaptchaCategory, CaptchaType } from "@/request/type"

interface GetCodeButtonType {
    address: string,
    type: CaptchaType,
    category: CaptchaCategory
}

const GetCodeButton: FC<GetCodeButtonType> = (props) => {

    const { address, type, category } = props

    const { messages } = useIntl()

    const [times, setTimes] = useState<number>(60)

    const [disabled, setDisabled] = useState<boolean>(false)

    const countDown = () => {
        let time = 60
        let timer: any = null

        timer = setInterval(() => {
            time--
            setTimes(time)
            if (time <= 0) {
                setDisabled(false)
                clearInterval(timer)
                time = 60
            }
        }, 1000)
    }

    const sendCode = () => {
        if (!address) {
            message.warning(messages['please_enter_email'])
            return false
        }

        if (disabled) {
            return false
        }

        SendCaptcha({
            recipient: address,
            type,
            category
        }).then(res => {
            console.log(res)
            setDisabled(true)
            countDown()
        })

    }

    return (
        <>
            <span onClick={ () => sendCode() } className={ disabled ? 'btn-warning disabled' : 'btn-warning' }>
                { disabled ? `${ messages['Resend'] }(${ times })` : messages['send'] }
            </span>
        </>
    )
}

export default GetCodeButton
