import React, { FC, useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { Button, Form, Space, Radio, Checkbox, message } from "antd"
import { useNavigate } from "react-router-dom"
import { GetQuestionnaire, SubQuestionnaire } from "@/request/api"
import { getPassportQuesList } from "@/store/reducers/infoSlice"
import { useAppDispatch } from "@/store/hook"

export interface QuestionStepType {
    setStep: Function
}

const StepTwo: FC<QuestionStepType> = (props) => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { setStep } = props

    const dispatch = useAppDispatch()

    const [form] = Form.useForm()

    const [quesList, setQuesList] = useState<[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const next = () => {
        form.validateFields().then((values: any) => {
            for(let key in values) {
                if (!values[key]?.length) {
                    values[key] = values[key].toString().split('_')
                }
            }
            setLoading(true)
            SubQuestionnaire({ answers: values }).then(() => {
                dispatch(getPassportQuesList())
                setLoading(false)
                message.success(messages['submitted_successfully'])
                navigate('/app/account')
            }).catch(() => setLoading(false))
        })
    }

    const getQuestionnaire = () => {
        GetQuestionnaire().then((res: any) => {
            setQuesList(res?.data)
        })
    }

    useEffect(() => {
        getQuestionnaire()
    }, [])

    return (
        <div className={ 'max-width-800 block-line' }>
            {
                quesList?.length ?
                    <div>
                        <Form
                            form={ form }
                            name="basic"
                            autoComplete="off"
                            layout={ 'vertical' }
                            className={ 'M-form' }
                        >
                            {
                                quesList.map((item: any, index) =>
                                    <div key={ item?.id }>
                                        <span className={ 'ques-label' }>Question { index + 1 }.</span>
                                        {
                                            item?.answer_type === 'radio' ?
                                                <Form.Item
                                                    className={ 'M-ques-radio no-border-b' }
                                                    label={ item?.title }
                                                    // name={ `${ item?.id }_${ item?.answer_type }` }
                                                    name={ item?.id }
                                                    rules={ [
                                                        { required: true, message: 'required' }
                                                    ] }
                                                >
                                                    <Radio.Group>
                                                        <Space direction="vertical">
                                                            {
                                                                item?.answers?.length ? item?.answers.map((col: any) =>
                                                                        <Radio key={ col?.id } value={ col?.id }>{ col?.title }</Radio>
                                                                ) : null
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item> :

                                                <Form.Item
                                                    className={ 'M-ques-radio no-border-b' }
                                                    label={ item?.title }
                                                    // name={ `${ item?.id }_${ item?.answer_type }` }
                                                    name={ item?.id }
                                                    valuePropName="checked"
                                                    rules={ [
                                                        { required: true, message: 'required' }
                                                    ] }
                                                >
                                                    <Checkbox.Group>
                                                        <Space direction="vertical">
                                                            {
                                                                item?.answers?.length ? item?.answers.map((col: any) =>
                                                                    <Checkbox key={ col?.id } value={ col?.id }>{ col?.title }</Checkbox>
                                                                ) : null
                                                            }
                                                        </Space>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                        }

                                    </div>
                                )
                            }

                        </Form>
                        <div className={ `btn-group mar-t-40` }>
                            <Space>
                                <Button
                                    className={ 'M-btn-42' }
                                    onClick={ () => setStep(1) }
                                >
                                    { messages['back'] }
                                </Button>
                                <Button
                                    className={ 'M-btn-42 min-width-180' }
                                    type='primary'
                                    loading={ loading }
                                    onClick={ () => next() }
                                >
                                    { messages['next'] }
                                </Button>
                            </Space>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default StepTwo
