import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useIntl } from "react-intl"

interface BackBtnType {
    backPath: string,
    title: string,
}

const BackBtn: FC<BackBtnType> = (props) => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const { backPath, title } = props

    return (
        <div className={ 'backBtn' }>
            <img
                onClick={ () => navigate(backPath) } className={ 'pointer' }
                src={ require('@/static/img/arrow-left-thin.png').default } alt="back"
            />
            <span
                onClick={ () => navigate(backPath) }
                className={ 'font-size-16 text-primary pointer' }
            >
                { messages[title] }
            </span>
        </div>
    )
}

export default BackBtn
