// 通过url参数 lang 改变语言
import { changeLang } from '@/store/reducers/commonSlice'
import { useAppDispatch } from "@/store/hook"
import { useSearchParams } from "react-router-dom"

const useParamsLang = () => {

    const dispatch = useAppDispatch()

    const [search] = useSearchParams()

    const lang = search.get('lang')

    const paramsChangeLang = () => {
        if (lang) {
            dispatch(changeLang(lang))
        }
    }

    return {
        paramsChangeLang
    }
}

export default useParamsLang
