import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// import { ReduxStore, StoreContext } from './store'
import { Provider } from 'react-redux'
import { store } from './store'

ReactDOM.render(
    <Provider store={ store }>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
