import React, { FC } from "react"
import { Row, Col } from "antd"
import Style from '@/style/view/appInner.module.less'
import { useIntl } from "react-intl"
import { officialWebsite } from "@/config"
import { useAppSelector } from "@/store/hook"
import { agreementList } from "@/tool/config"

const Footers: FC = () => {

    const { messages } = useIntl()

    const { currentLang } = useAppSelector(state => state.common) // 当前语言

    return (
        <footer className={ `${ Style.footers } container` }>
            <Row>
                <Col lg={ 12 } span={ 24 }>
                    <span className={ Style.text }>Motto Global</span>
                    <span className={ Style.text }>Asset Management Limited.</span>
                </Col>
                <Col lg={ 12 } span={ 24 } className={ Style.itemsWrap }>
                    {
                        agreementList.map((item) =>
                            <div key={ item.title } className={ Style.items }>
                                <a
                                    href={ officialWebsite + item.link[currentLang] }
                                    target='_blank'
                                >
                                    { messages[item.title] }
                                </a>
                            </div>
                        )
                    }
                </Col>
            </Row>
        </footer>
    )
}

export default Footers
