import { useAppSelector } from "@/store/hook"

const useCountry = () => {

    const { country } = useAppSelector(state => state.common)

    const fetchCountry = (id: number | string): any => {
        if (country?.length) {
            return country.filter((item: any) => String(id) === String(item?.id))[0]
        }
    }

    return {
        fetchCountry
    }
}

export default useCountry
