import React, { FC, useState, useRef, useEffect } from "react"
import Style from '@/style/view/verificationId.module.less'
import BackBtn from "@/component/common/backBtn"
import { useIntl } from "react-intl"
import { Row, Col, Space, Button, Modal, message } from 'antd'
import { useNavigate } from "react-router-dom"
import InfoForm from "@/component/verificationId/InfoForm"
import UploadDoc from "@/component/verificationId/UploadDoc"
import UploadAddress from "@/component/verificationId/UploadAddress"
import { RefFun } from "@/type"
import { UpdateInfoDetail, UpdateIdent } from "@/request/api"
import useInfo from "@/hook/useInfo"
import { useAppDispatch, useAppSelector } from "@/store/hook"
import { getIdent, getIdentProgress } from "@/store/reducers/infoSlice"

const VerificationId: FC = () => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const InfoFormRef = useRef<RefFun>()

    const DocumentsRef = useRef<RefFun>()

    const { fetchInfo } = useInfo()

    const dispatch = useAppDispatch()

    const { identProgress } = useAppSelector(state => state.info)

    const [loading, setLoading] = useState<boolean>(false)

    //InfoForm
    const [openInfoForm, setOpenInfoForm] = useState<boolean>(false)
    const [infoFormData, setInfoFormData] = useState<any>()

    //DocumentsForm
    const [openDocuments, setOpenDocuments] = useState<boolean>(false)
    const [documentsData, setDocumentsData] = useState<any>()
    const [imageFront, setImageFront] = useState<string>('')
    const [imageBack, setImageBack] = useState<string>('')

    //Upload Proof Address
    const [openAddress, setOpenAddress] = useState<boolean>(false)
    const [AddressData, setAddressData] = useState<string>('')

    //Submitted
    const [openSubmitted, setOpenSubmitted] = useState<boolean>(false)

    //InfoForm
    const infoFormBtnHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "cancel":
                //InfoFormRef?.current?.reset()
                setOpenInfoForm(false)
                break
            case "confirm":
                InfoFormRef?.current?.getValue()
                break
        }
    }

    const infoFormCallBack = (values: any) => {
        setLoading(true)
        const { address, country_id, first_name, last_name, year, month, day, postcode, tax_id, tax_residence } = values
        UpdateInfoDetail({
            address,
            country_id,
            postcode, tax_id,
            tax_residence,
            name: `${ first_name } ${ last_name }`,
            birthday: `${ year }-${ month }-${ day }`
        }).then(() => {
            fetchInfo()
            setLoading(false)
            message.success(messages['submitted_successfully'])
            setInfoFormData(values)
            setOpenInfoForm(false)
            setOpenDocuments(true)
        }).catch(() => setLoading(false))
    }

    //DocumentsForm
    const documentsBtnHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "cancel":
                DocumentsRef?.current?.reset()
                setDocumentsData(false)
                break
            case "confirm":
                DocumentsRef?.current?.getValue()
                break
        }
    }

    const documentsCallBack = (values: any) => {
        const { imageFront, imageBack } = values
        if (!imageFront || !imageBack) {
            message.warning(messages['Please_select_file'])
            return false
        }
        setDocumentsData(values)
        setOpenDocuments(false)
        setOpenAddress(true)
    }

    //Address
    const AddressBtnHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "cancel":
                setOpenAddress(false)
                break
            case "confirm": //提交所有数据
                if (!AddressData) {
                    message.warning(messages['Please_select_file'])
                    return false
                }
                setLoading(true)
                const { type, imageFront, imageBack} = documentsData
                UpdateIdent({
                    type,
                    image_front: imageFront,
                    image_back: imageBack,
                    image_address: AddressData
                }).then((_res: any) => {
                    dispatch(getIdent())
                    dispatch(getIdentProgress())
                    setOpenAddress(false)
                    setOpenSubmitted(true)
                }).catch(() => setLoading(false))
                break
        }
    }

    useEffect(() => {
        if (identProgress !== null && identProgress !== 'Closed') {
            navigate(-1)
        }
    },[identProgress])

    return (
        <div className={ Style.verificationId }>
            <header className={ 'page-title' }>
                <span>{ messages['ID_Verification'] }</span>
                <BackBtn title={ 'Back_to_My_Account' } backPath={ '/app/account' }/>
            </header>
            <div className={ 'block-line' }>
                <header className={ `${ Style.header }` }>{ messages['ver_des'] }</header>
                <Row gutter={ 26 }>
                    <Col lg={ 12 } span={ 24 }>
                        <div className={ 'card-no-header mar-b-16 min-height-338' }>
                            <header className={ Style.cardTitle }>
                                <img src={ require('@/static/img/card-gray.png').default } alt="file"/>
                                <span>{ messages['ID_Documents'] }</span>
                            </header>
                            <div className={ Style.list }>
                                <div className={ 'font-size-16' }>{ messages['One_of_the_following'] }</div>
                                <ul>
                                    <li>
                                        <span className={ Style.listTitle }>- { messages['Passport'] }</span>
                                        <span className={ Style.des }>{ messages['Passport_tip'] }</span>
                                    </li>
                                    <li>
                                        <span className={ Style.listTitle }>- { messages['ID_card'] }</span>
                                        <span className={ Style.des }>{ messages['ID_tip'] }</span>
                                    </li>
                                    <li>
                                        <span className={ Style.listTitle }>- { messages['Driver_license'] }</span>
                                        <span className={ Style.des }>{ messages['ID_tip'] }</span>
                                    </li>
                                </ul>
                            </div>
                            <footer className={ 'font-size-16' }>{ messages['Documents_tip'] }</footer>
                        </div>
                    </Col>
                    <Col lg={ 12 } span={ 24 }>
                        <div className={ 'card-no-header mar-b-16 min-height-338' }>
                            <header className={ Style.cardTitle }>
                                <img src={ require('@/static/img/file-document-outline.png').default } alt="file"/>
                                <span>{ messages['Proof_of_Address'] }</span>
                            </header>
                            <div className={ Style.list }>
                                <div className={ 'font-size-16 mar-b-26' }>{ messages['One_of_the_following'] }</div>
                                <ul>
                                    <li>
                                        <span className={ Style.listTitle }>- { messages['Utility_tip'] }</span>
                                    </li>
                                    <li>
                                        <span className={ Style.listTitle }>- { messages['Bank_statement'] }</span>
                                    </li>
                                    <li>
                                        <span
                                            className={ Style.listTitle }>- { messages['Tax_payment_certificate'] }</span>
                                    </li>
                                </ul>
                            </div>
                            <footer className={ 'font-size-16' }>{ messages['Documents_must'] }</footer>
                        </div>
                    </Col>
                </Row>
                <footer className={ Style.footer }>
                    <span>{ messages['Data_format'] }: GIF, JPG, PNG, PDF</span>
                    <div className={ `${ Style.btnGroup } btn-group` }>
                        <Space>
                            <Button
                                className={ 'M-btn-42' }
                                onClick={ () => navigate(-1) }
                            >
                                { messages['back'] }
                            </Button>
                            <Button
                                className={ 'M-btn-42 min-width-180' }
                                type='primary'
                                onClick={ () => setOpenInfoForm(true) }
                            >
                                { messages['next'] }
                            </Button>
                        </Space>
                    </div>
                </footer>
            </div>
            {/*step1 Enter Info*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Enter_Personal_Information'] }
                open={ openInfoForm }
                onCancel={ () => infoFormBtnHandle('cancel') }
                okText={ messages['next'] }
                onOk={ () => infoFormBtnHandle('confirm')}
                confirmLoading={ loading }
            >
                <InfoForm ref={ InfoFormRef } callback={ infoFormCallBack }/>
            </Modal>
            {/*step2  Upload ID Documents*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Upload_ID_Documents'] }
                open={ openDocuments }
                onCancel={ () => setOpenDocuments(false) }
                okText={ messages['next'] }
                onOk={ () => documentsBtnHandle('confirm')}
                confirmLoading={ loading }
            >
                <UploadDoc ref={ DocumentsRef } callback={ documentsCallBack }/>
            </Modal>
            {/*step3 Upload Proof of Address */ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Upload_Proof_of_Address'] }
                open={ openAddress }
                onCancel={ () => setOpenAddress(false) }
                okText={ messages['Submit'] }
                onOk={ () => AddressBtnHandle('confirm') }
                confirmLoading={ loading }
            >
                <UploadAddress callback={ setAddressData }/>
            </Modal>
            {/*step4 Upload Submitted*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Submitted'] }
                open={ openSubmitted }
                onCancel={ () => setOpenSubmitted(false) }
                footer={ false }
            >
                <p className={ 'font-size-400 mar-b-32' }>{ messages['Submitted_tip'] }</p>
                <div className={ 'text-center' }>
                    <Button type='primary' onClick={ () => navigate('/app/account') }>{ messages['Go_to_My_Account'] } </Button>
                </div>
            </Modal>
        </div>
    )
}

export default VerificationId
