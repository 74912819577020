import jaText from './json/ja.json'
import jaJP from 'antd/es/locale/ja_JP'

const ja = {
    messages: {
        ...jaText
    },
    antd: jaJP,
    locale: 'ja'
}
export default ja
