import React, { FC, useEffect, useState } from "react"
import Style from '@/style/componet/errorMessage.module.less'
import { regular } from "@/tool/config"
import { useIntl } from "react-intl"

interface ErrorMessageType {
    text: string
}

const ErrorMessage: FC<ErrorMessageType> = (props) => {

    const { text } = props

    const { messages } = useIntl()

    const [passed, setPassed] = useState<boolean>(false) //验证是否通过

    const [tips, setTips] = useState<string>('')

    const verification = () => {
        let { alphanumeric, special, length } = regular
        if (!alphanumeric.test(text)) {
            setPassed(false)
            setTips(messages['alphanumeric_limit'] as string)
        } else if (!special.test(text)) {
            setPassed(false)
            setTips(messages['special_limit'] as string)
        } else if (!length.test(text)) {
            setPassed(false)
            setTips(messages['length_limit'] as string)
        } else {
            setPassed(true)
        }
    }

    useEffect(() => {
        verification()
    }, [text])

    return (
        <div>
            {
                passed || !text ? null :
                    <div className={ Style.errorMessage }>
                        { tips }
                    </div>
            }
        </div>
    )
}

export default ErrorMessage
