import React, { FC, useState, useEffect, useRef } from "react"
import SpinCom from "@/component/common/SpinCom"

const Schedule: FC = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const Iframe: any = useRef<HTMLIFrameElement>()
    const iframeLoad = () => {
        setLoading(true)
        if (Iframe?.current?.attachEvent) { // IE
            Iframe?.current?.attachEvent('onload', () => {
                setLoading(false)
            })
        } else { // 非IE
            if (Iframe?.current) {
                Iframe.current.onload = () => {
                    setLoading(false)
                }
            }
        }
    }

    useEffect(() => {
        iframeLoad()
    }, [])

    return (
        <div>
            {
                loading ? <SpinCom delay={ 1500 }/> : null
            }
            <div className={ loading ? 'hide' : 'show' } style={ { padding: '20px 0 0 0' } }>
                <iframe
                    ref={ Iframe }
                    style={ { border: "none", width: "100%", minHeight: 700 } }
                    src="https://meetings.hubspot.com/admin-motto?embed=true"
                />
            </div>
        </div>
    )
}

export default Schedule
