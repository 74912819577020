import React, { FC } from "react"
import { Empty } from 'antd'

const NoData: FC = () => {

    return (
        <div style={ { textAlign: 'center' } }>
            <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/>
        </div>
    )
}

export default NoData
