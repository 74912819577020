import React, { FC } from "react"
import { useIntl } from "react-intl"
import { Button, Space } from "antd"
import { useNavigate } from "react-router-dom"

export interface QuestionStepType {
    setStep: Function
}

const StepOne: FC<QuestionStepType> = (props) => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { setStep } = props

    return (
        <div className={ 'max-width-800 block-line' }>
            <p className={'mar-b-32 font-size-16'}>{ messages['question_tip1'] }</p>
            <p className={'mar-b-32 font-size-16'}>{ messages['question_tip2'] }</p>
            <div className={ `btn-group` }>
                <Space>
                    <Button
                        className={ 'M-btn-42' }
                        onClick={ () => navigate(-1) }
                    >
                        { messages['back'] }
                    </Button>
                    <Button
                        className={ 'M-btn-42 min-width-180' }
                        type='primary'
                        onClick={ () => setStep(2) }
                    >
                        { messages['Start'] }
                    </Button>
                </Space>
            </div>
        </div>
    )
}

export default StepOne
