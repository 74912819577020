import { website } from "@/config"

export const treeMenu = (data: any[], pid: string): any[] => {
    let result = [], temp
    for (let i = 0; i < data.length; i++) {
        if (data[i].meta.pid === pid) {
            let obj = {
                ...data[i],
            }
            temp = treeMenu(data, data[i].meta.id)
            if (temp.length) {
                obj.children = temp
            }
            result.push(obj)
        }
    }
    return result
}

export const routeFilter = (routes: any) => { // 展开路由
    return routes.map((item: any) => {
        if (item.children) {
            return [item, ...routeFilter(item.children)]
        } else {
            return [item]
        }
    }).flat()
}

export const is_mobile = () => {
    return window.screen.width <= 750
}

export const dateList = (type: | 'year' | 'month' | 'day') => {
    let now = new Date()
    let now_year = now.getFullYear() //获取当前年份
    let yearList = []
    let monthList = []

    for (let i = now_year; i >= now_year - 99; i--) {
        yearList.push(i)
    }

    for (let i = 1; i <= 12; i++) {
        let item = i < 10 ? '0' + i : i
        monthList.push(item)
    }

    let maxDay = 31
    let dayList = []

    for (let i = 1; i <= maxDay; i++) {
        let item = i < 10 ? '0' + i : i
        dayList.push(item)
    }

    switch (type) {
        case "year":
            return yearList
        case "month":
            return monthList
        case "day":
            return dayList
        default:
            return []
    }
}

export const download = (attachment: string) => {
    window.open(`${ website }/attachments/${ attachment }?action=download`)
}

export const downloadDoc = (attachment: string) => {
    window.open(`${ website }${ attachment }?action=download`)
}

// export const dayList = (year: string, month: string) => {
//     let maxDay = 0
//     let data = []
//     if (month === '01' || month === '03' || month === '05' || month === '07' || month === '08' || month === '10' || month === '12') {
//         maxDay = 31
//     } else if (month === '02') {
//         if ((Number(year) % 4 === 0 && Number(year) % 100 !== 0) || Number(year) % 400 === 0) {
//             maxDay = 29
//         } else {
//             maxDay = 28
//         }
//     } else {
//         maxDay = 30
//     }
//     for (let i = 1; i <= maxDay; i++) {
//         let item = i < 10 ? '0' + i : i
//         data.push(item)
//     }
//     return data
// }
