import React, { ForwardRefRenderFunction, ReactNode, useEffect } from "react"
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from "react-router-dom"
import LoginCom from "@/view/login/login"
import Register from "../login/register"
import history from "@/lib/history"
import { ConfigProvider } from "antd"
import { useAppSelector, useAppDispatch } from "@/store/hook"
import { messages } from '@/locale'
import { IntlProvider } from "react-intl"
import AppInner from "@/view"
import ResetPass from "@/view/login/resetPass"
import { getCountry, getLang } from "@/store/reducers/commonSlice"

ConfigProvider.config({
    theme: {
        primaryColor: '#1681E5',
    }
})

const Layout: ForwardRefRenderFunction<ReactNode> = () => {

    const { currentLang } = useAppSelector(state => state.common)

    const locale = messages[currentLang].antd

    const dispatch = useAppDispatch()

    const router = () => {
        return (
            // @ts-ignore
            <Router history={ history }>
                <Routes>
                    <Route path='/' element={ <Navigate to="/login"/> }/>
                    <Route path='/login' element={ <LoginCom/> }/>
                    <Route path='/resetPassword' element={ <ResetPass/> }/>
                    <Route path='/register' element={ <Register/> }/>
                    <Route path='/app/*' element={ <AppInner/> }/>
                </Routes>
            </Router>
        )
    }

    useEffect(() => {
        dispatch(getCountry())
        dispatch(getLang())
    }, [])

    return (
        <div>
            <ConfigProvider locale={ locale }>
                <IntlProvider locale={ currentLang } messages={ messages[currentLang].messages }>
                    { router() }
                </IntlProvider>
            </ConfigProvider>
        </div>

    )
}

export default Layout
