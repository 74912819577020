import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Row, Col, Pagination } from "antd"
import { GetPortfolios } from "@/request/api"
import moment from "moment"
import { download } from "@/tool"
import SpinCom from "@/component/common/SpinCom"
import NoData from "@/component/common/noData"

const Portfolios: FC = () => {

    const { messages } = useIntl()

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [dataList, setDataList] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const getPortfolios = (page_: number = page, pageSize_: number = pageSize) => {
        setLoading(true)
        GetPortfolios({ page: page_, page_size: pageSize_ }).then((res: any) => {
            setLoading(false)
            setTotal(res?.data?.total)
            setDataList(res?.data?.data)
        }).catch(() => setLoading(false))
    }

    const pageChange = (page_: number, pageSize_: number) => {
        setPage(page_)
        setPageSize(pageSize_)
        getPortfolios(page_, pageSize_)
    }

    useEffect(() => {
        getPortfolios(1)
    }, [])

    return (
        <div>
            <header className={ `page-title` }>{ messages['portfolios'] }</header>
            <div className={ `table-wrap` }>
                <header>
                    <Row>
                        <Col lg={ 15 } span={ 0 }>
                            <span className={ 'title break-word' }>{ messages['Report'] }</span>
                        </Col>
                        <Col lg={ 6 } span={ 0 }>
                            <span className={ 'title' }>{ messages['Issue_Date'] }</span>
                        </Col>
                        <Col lg={ 3 } span={ 0 }>
                            <span className={ 'title' }>{ messages['Action'] }</span>
                        </Col>
                    </Row>
                </header>
                <ul>
                    {
                        loading ? <SpinCom/> : dataList?.length ? dataList.map((item: any) =>
                            <li key={ item?.id }>
                                <Row>
                                    <Col className={ 'item' } lg={ 15 } span={ 24 }>
                                        <span className={ 'font-weight-500' }>{ item?.title }</span>
                                    </Col>
                                    <Col className={ 'item' } lg={ 6 } span={ 24 }>
                                        <span
                                            className={ 'font-weight-400' }
                                        >
                                            { moment.unix(item?.created_at).format('YYYY-MM-DD HH:mm') }
                                        </span>
                                    </Col>
                                    <Col className={ 'item action-wrap' } lg={ 3 } span={ 24 }>
                                        <span
                                            onClick={ () => download(item?.attachment) }
                                            className={ 'action' }
                                        >
                                            { messages['Download'] }
                                        </span>
                                    </Col>
                                </Row>
                            </li>
                        ) : <NoData/>
                    }
                </ul>
                <div className={ `M-pagination clearfix ${ dataList?.length ? 'show' : 'hide' }` }>
                    <div className={ 'fr' }>
                        <Pagination current={ page } total={ total } onChange={ pageChange } showSizeChanger/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolios
