import zhText from './json/zh.json'
import zhCN from 'antd/es/locale/zh_CN'

const zh = {
    messages: {
        ...zhText
    },
    antd: zhCN,
    locale: 'cn'
}
export default zh
