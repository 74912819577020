import React, { FC, useState } from "react"
import { useIntl } from "react-intl"
import Style from '@/style/view/login.module.less'
import LoginHeader from "@/component/login/header"
import { Button, Form, Input } from "antd"
import GetCodeButton from "@/component/common/getCodeButton"

interface RegisterStepTwoType {
    setStep: Function,
    callback?: Function,
    loading?: boolean
}

export interface RegisterStepTwoData {
    email: string,
    captcha: string
}

const RegisterStepTwo: FC<RegisterStepTwoType> = (props) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback, setStep, loading } = props

    const [email, setEmail] = useState<string>('')

    const emailChange = (e: any) => {
        setEmail(e.target.value)
    }

    const next = () => {
        form.validateFields().then((values: any) => {
            callback && callback(values)
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader step={ 2 } title={ 'register_email_address' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >
                        <Form.Item
                            label={ messages['email_address'] }
                            name={ 'email' }
                            rules={ [
                                {
                                    required: true,
                                },
                                {
                                    type: 'email',
                                    message: messages['not_valid_email'] as string,
                                }
                            ] }
                        >
                            <Input onChange={ emailChange }/>
                        </Form.Item>
                        <div className={ Style.tips }>{ messages['register_tip'] }</div>
                        <div className={ 'getCodeItem' }>
                            <Form.Item
                                label={ messages['verification_code'] }
                                name={ 'captcha' }
                                rules={ [{ required: true }] }
                            >
                                <Input placeholder={ messages['please_enter_verification'] as string }/>
                            </Form.Item>
                            <div className={'getCodeButton'}>
                                <GetCodeButton address={ email } type={ 'email' } category={ 'register' }/>
                            </div>
                        </div>
                        <div className={ 'M-btn-warp' }>
                            <Button
                                loading={ loading }
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                            >
                                { messages['verify'] }
                            </Button>
                        </div>
                        <div className={ `M-btn-warp ${ Style.backWrap }` }>
                            <Button
                                onClick={ () => setStep(1) }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                ghost
                            >
                                { messages['back'] }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default RegisterStepTwo
