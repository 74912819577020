import React, { FC, useState } from "react"
import UploadAntd from "@/component/common/uoloadAntd"
import { useAppSelector } from "@/store/hook";

export interface UploadAddressType {
    callback?: Function
}

const UploadAddress: FC<UploadAddressType> = (props) => {

    const { callback } = props

    const { ident } = useAppSelector(state => state.info) // 认证信息

    return (
        <div>
            <UploadAntd image={ ident?.image_address } category={ 'proof-address' } callback={ callback } />
        </div>
    )
}

export default UploadAddress
