import React, { FC, useState } from "react"
import { useIntl } from "react-intl"
import Style from '@/style/view/login.module.less'
import LoginHeader from "@/component/login/header"
import { Button, Form, Input, message, Checkbox } from "antd"
import { regular } from "@/tool/config"
import { agreementLink } from "@/tool/filiter"
import ErrorMessage from "@/component/common/errorMessage"

interface RegisterStepThreeType {
    setStep: Function
    loading: boolean
    callback?: Function,
}

export interface RegisterThreeData {
    password: string,
    password_confirm: string
}

const RegisterStepThree: FC<RegisterStepThreeType> = (props) => {

    const { messages, formatMessage } = useIntl()

    const [form] = Form.useForm()

    const { callback, setStep, loading } = props

    const [password, setPassword] = useState<string>('')

    const passChange = (e: any) => {
        setPassword(e.target.value)
    }

    const next = () => {
        form.validateFields().then((values: any) => {
            const { agree } = values
            if (!agree) {
                message.warning(messages['please_agree_policy'])
            } else {
                callback && callback(values)
            }
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader step={ 3 } title={ 'set_password' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >
                        <Form.Item
                            label={ messages['password'] }
                            name={ 'password' }
                            rules={ [
                                { required: true },
                                { pattern: regular?.password, message: '' }
                            ] }
                        >
                            <Input.Password onChange={ passChange }/>
                        </Form.Item>
                        <Form.Item
                            label={ messages['confirm_password'] }
                            name={ 'password_confirm' }
                            rules={ [
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(messages['passwords_not_match'] as string))
                                    },
                                }),
                            ] }
                        >
                            <Input.Password/>
                        </Form.Item>
                        <ErrorMessage text={ password }/>
                        <div className={ Style.tips2 }>{ messages['password_tip'] }</div>
                        <div className={ Style.agreeWrap }>
                            <Form.Item
                                name="agree"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>
                                    {
                                        formatMessage(
                                            { id: 'agree_policy' }, {
                                                Terms:
                                                    <a
                                                        target='_blank'
                                                        href={ agreementLink('Terms_of_Use') }
                                                    >
                                                        { messages['terms'] as string }
                                                    </a>,
                                                policy:
                                                    <a
                                                        target='_blank'
                                                        href={ agreementLink('Privacy_Policy') }
                                                    >
                                                        { messages['policy'] as string }
                                                    </a>,
                                            })
                                    }
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className={ 'M-btn-warp' }>
                            <Button
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                loading={ loading }
                            >
                                { messages['send'] }
                            </Button>
                        </div>
                        <div className={ `M-btn-warp ${ Style.backWrap }` }>
                            <Button
                                onClick={ () => setStep(2) }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                ghost
                            >
                                { messages['back'] }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default RegisterStepThree
