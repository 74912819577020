import React, { ForwardRefRenderFunction, ReactNode, useImperativeHandle, forwardRef, useEffect } from "react"
import { useIntl } from "react-intl"
import { Form, Select } from "antd"
import { useAppSelector } from "@/store/hook";

const { Option } = Select

interface ChangeLanguageFormType {
    callback?: Function
}

const ChangeLanguage: ForwardRefRenderFunction<ReactNode, ChangeLanguageFormType> = (props, ref) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback } = props

    const { langList } = useAppSelector(state => state.common)

    const { info } = useAppSelector(state => state.info)

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback(values)
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    useEffect(() => {
        form.setFieldsValue({
            locale: info?.locale
        })
    }, [info])

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >
            <Form.Item
                label={ messages['LANGUAGE'] }
                name='locale'
                rules={ [
                    { required: true }
                ] }
            >
                <Select>
                    {
                        langList?.length ? langList.map((item: any) =>
                            <Option key={ item?.id } value={ item?.name }>{ item?.title }</Option>
                        ) : null
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}

export default forwardRef(ChangeLanguage)
