import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Row, Col, Pagination } from "antd"
import { useNavigate } from "react-router-dom"
import { GetNews } from "@/request/api"
import moment from "moment"
import { monthFilter } from "@/tool/filiter"
import SpinCom from "@/component/common/SpinCom"
import NoData from "@/component/common/noData"

const News: FC = () => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [dataList, setDataList] = useState<[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getNews = (page_: number = page, pageSize_: number = pageSize) => {
        setLoading(true)
        GetNews({ page: page_, page_size: pageSize_ }).then((res: any) => {
            setLoading(false)
            setTotal(res?.data?.total)
            setDataList(res?.data?.data)
        }).catch(() => setLoading(false))
    }

    const pageChange = (page_: number, pageSize_: number) => {
        setPage(page_)
        setPageSize(pageSize_)
        getNews(page_, pageSize_)
    }

    useEffect(() => {
        getNews(1)
    }, [])

    return (
        <div>
            <header className={ `page-title` }>{ messages['news'] }</header>
            <div className={ `table-wrap max-width-800` }>
                {
                    loading ? <SpinCom/> : dataList?.length ?
                        <ul>
                            {
                                dataList?.map((item: any) =>
                                    <li
                                        onClick={ () => navigate(`/app/news/${ item?.id }`) }
                                        className={ 'timeBar pointer' }
                                        key={ item?.id }
                                    >
                                        <Row>
                                            <div className={ 'time' }>
                                                <span>{ monthFilter(moment.unix(item?.created_at).format('MM')) }</span>
                                                <span>{ moment.unix(item?.created_at).format('DD') }</span>
                                            </div>
                                            <Col className={ 'item mar-b-14 news-title' } span={ 24 }>
                                                <span
                                                    className={ 'font-weight-500 font-size-18 margin-b-14 break-word' }>{ item?.title }</span>
                                            </Col>
                                            <Col className={ 'item mar-b-14' } span={ 24 }>
                                                <Row>
                                                    <Col span={ 12 }>
                                                        <span
                                                            className={ 'date' }>{ moment.unix(item?.created_at).format('YYYY-MM-DD HH:mm') }</span>
                                                    </Col>
                                                    <Col span={ 12 } className={ 'text-right' }>
                                                        <span className={ 'posted' }>Posted:</span>
                                                        <span
                                                            className={ 'font-size-16 break-word' }>{ item?.contributor }</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                            }
                        </ul>
                        : <NoData/>
                }
                <div className={ `M-pagination clearfix ${ dataList?.length ? 'show' : 'hide' }` }>
                    <div className={ 'fr' }>
                        <Pagination current={ page } total={ total } onChange={ pageChange } showSizeChanger/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News
