import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Row, Col, Pagination } from "antd"
import { GetOpportunity } from "@/request/api"
import { useAppSelector } from "@/store/hook"
import { download } from "@/tool"
import SpinCom from "@/component/common/SpinCom"
import NoData from "@/component/common/noData"

const Opportunities: FC = () => {

    const { messages } = useIntl()

    const { currentLang } = useAppSelector(state => state.common) // 当前语言

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [dataList, setDataList] = useState<[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getOpportunity = (page_: number = page, pageSize_: number = pageSize) => {
        setLoading(true)
        GetOpportunity({ page: page_, page_size: pageSize_ }).then((res: any) => {
            setLoading(false)
            setTotal(res?.data?.total)
            setDataList(res?.data?.data)
        }).catch(() => setLoading(false))
    }

    const pageChange = (page_: number, pageSize_: number) => {
        setPage(page_)
        setPageSize(pageSize_)
        getOpportunity(page_, pageSize_)
    }

    useEffect(() => {
        getOpportunity(1)
    }, [currentLang])

    return (
        <div>
            <header className={ `page-title` }>{ messages['opportunities'] }</header>

            <div className={ `table-wrap` }>
                {
                    loading ? <SpinCom/> : dataList?.length ?
                        <ul>
                            {
                                dataList.map((item: any) =>
                                    <li key={ item?.id } className={ 'beforeBar' }>
                                        <Row>
                                            <Col className={ 'item mar-b-14' } span={ 24 }>
                                                <span
                                                    className={ 'font-weight-500 font-size-22 margin-b-14 break-word' }>{ item?.title }</span>
                                            </Col>
                                            <Col className={ 'item mar-b-14' } span={ 24 }>
                                                <span
                                                    className={ 'font-weight-400 break-word' }>{ item?.description }</span>
                                            </Col>
                                            <Col className={ 'item action-wrap' } span={ 24 }>
                                                <div className={ 'clearfix' }>
                                                    <span onClick={ () => download(item?.attachment) }
                                                          className={ 'action fr' }>{ messages['Download'] }</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                            }
                        </ul>
                        : <NoData/>
                }
                <div className={ `M-pagination clearfix ${ dataList?.length ? 'show' : 'hide' }` }>
                    <div className={ 'fr' }>
                        <Pagination current={ page } total={ total } onChange={ pageChange } showSizeChanger/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Opportunities
