import React, { FC } from "react"
import './style/index.css'
import './style/tool.less'

import Layout from "./view/router"

const App: FC = () => {
    return (
        <div>
            <Layout />
        </div>
    )
}

export default App
