import React, { ForwardRefRenderFunction, ReactNode, useImperativeHandle, forwardRef, useEffect } from "react"
import { useIntl } from "react-intl"
import { Form, Select, Input } from "antd"
import { useAppSelector } from "@/store/hook";

const { Option } = Select

interface LegalFormFormType {
    record: any
    callback?: Function
}

const LegalForm: ForwardRefRenderFunction<ReactNode, LegalFormFormType> = (props, ref) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { record, callback } = props

    const { country } = useAppSelector(state => state.common)

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback(values)
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    useEffect(() => {
        if (record?.name) {
            const { name, country_id } = record
            form.setFieldsValue({
                name,
                country_id
            })
        }
    }, [record])

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >

            <Form.Item
                label={ messages['Legal_Entity_Name'] }
                name='name'
                rules={ [
                    { required: true }
                ] }
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label={ messages['Country_of_Incorporation'] }
                name='country_id'
                rules={ [
                    { required: true }
                ] }
            >
                <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {
                        country?.length ? country.map((item: any) =>
                            <Option key={ item?.id } value={ item?.id } label={ item?.title }>{ item?.title }</Option>
                        ) : null
                    }
                </Select>
            </Form.Item>

        </Form>
    )
}

export default forwardRef(LegalForm)
