
export const langList = [
    {
        title: 'English',
        key: 'en'
    },
    {
        title: '简体中文',
        key: 'zh'
    },
    {
        title: '日本語',
        key: 'ja'
    }
]

export const regular = {
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&])[A-Za-z\d$@!%*?&]{8,}/, //至少包含8个或更多字符，并包含字母、大写字母、数字和符号
    length: /.{8,}/, //最少八位,
    alphanumeric: /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])/, //需包含大小写字母和数字,
    special: /^(?=.*?[#?!@$%^&*-])/, //需包含特殊字符,
    number: /^[0-9]*$/, //纯数字,
}

export interface agreementListType {
    title: string,
    link: {
        en: string,
        ja: string,
        zh: string
    }
}

export const agreementList: agreementListType[] = [
    {
        title: 'Contact',
        link: {
            en: 'en/contact-us/',
            ja: 'ja/contact-us/',
            zh: 'contact-us/'
        }
    },
    {
        title: 'Terms_of_Use',
        link: {
            en: 'en/terms-of-use/',
            ja: 'ja/terms-of-use/',
            zh: 'terms-of-use/'
        }
    },
    {
        title: 'Privacy_Policy',
        link: {
            en: 'en/privacy-policy/',
            ja: 'ja/privacy-policy/',
            zh: 'privacy-policy/'
        }
    },
    {
        title: 'Platform_Security',
        link: {
            en: 'en/platform-security/',
            ja: 'ja/platform-security/',
            zh: 'platform-security/'
        }
    }
]

export const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
