import React, { FC, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import routes, { routeType } from "@/router"
import { routeFilter } from "@/tool"
import Navbar from "@/component/common/navbar"
import Style from '@/style/view/appInner.module.less'
import Footers from "@/component/common/footers"
import useInfo from "@/hook/useInfo"
import { getIdentTypes, getAttachmentCategory } from "@/store/reducers/commonSlice"
import { useAppDispatch, useAppSelector } from "@/store/hook"

const RouteList = routeFilter(routes)

const AppInner: FC = () => {

    const dispatch = useAppDispatch()

    const { fetchInfo, fetchProgress } = useInfo()

    const { pathname } = useLocation()

    const { loginFetch } = useAppSelector(state => state.common)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        if (!loginFetch) { //登录已获取便不再重复获取
            fetchProgress()
        }
        fetchInfo()
        dispatch(getIdentTypes())
        dispatch(getAttachmentCategory())
    }, [loginFetch])

    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div className={ `${ Style.main } container bg-white` }>
                <Routes>
                    {
                        RouteList.map((item: routeType) => item.path && <Route path={ item.path }
                                                                               key={ item.path }
                                                                               element={
                                                                                   <item.component/>
                                                                               }
                        />)
                    }
                </Routes>
            </div>
            <Footers/>
        </div>
    )
}

export default AppInner
