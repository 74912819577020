// 公用
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { GetCountry, GetLang, GetIdentTypes, GetAttachmentCategory } from "@/request/api"
// import { arrObjSort } from '@/tool/filiter'

export type Lang = 'zh' | 'en' | 'ja'

export const localeLangList = ['zh', 'zh_cn', 'en', 'ja']

interface CounterState {
    currentLang: Lang
    country: any[]
    langList: []
    identTypes: []
    attachmentCategory: [],
    loginFetch: boolean
}

const initialState: CounterState = {
    currentLang: window.localStorage.getItem('lang') as Lang || 'en', // 当前语言
    country: [],
    langList: [],
    identTypes: [],
    attachmentCategory: [],
    loginFetch: false
}

export const getCountry = createAsyncThunk( // 获取国家
    'common/getCountry',
    async () => {
        return await GetCountry()
    }
)

export const getLang = createAsyncThunk( // 获取语言
    'common/getLang',
    async () => {
        return await GetLang()
    }
)

export const getIdentTypes = createAsyncThunk( // 获取证件类型
    'common/getIdentTypes',
    async () => {
        return await GetIdentTypes()
    }
)

export const getAttachmentCategory = createAsyncThunk( // 获取附件分类
    'common/getAttachmentCategory',
    async () => {
        return await GetAttachmentCategory()
    }
)

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        changeLang: (state, action: PayloadAction<any>) => {
            if (action.payload && localeLangList.includes(action.payload.toLowerCase())) {
                let lang = action.payload.toLowerCase()
                if (action.payload.toLowerCase() === 'zh_cn') {
                    lang = 'zh'
                }
                state.currentLang = lang
                window.localStorage.setItem('lang', lang)
            }
        },
        changeLoginFetch: (state, action: PayloadAction<any>) => {
            state.loginFetch = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountry.fulfilled, (state, action: PayloadAction<any>) => {
                state.country = action.payload.data
                // if (action?.payload?.data?.length) {
                //     state.country = arrObjSort(action.payload.data, 'title')
                // }
            })
            .addCase(getLang.fulfilled, (state, action: PayloadAction<any>) => {
                state.langList = action.payload.data
            })
            .addCase(getIdentTypes.fulfilled, (state, action: PayloadAction<any>) => {
                state.identTypes = action.payload.data
            })
            .addCase(getAttachmentCategory.fulfilled, (state, action: PayloadAction<any>) => {
                state.attachmentCategory = action.payload.data
            })
    },
})

export const { changeLang, changeLoginFetch } = commonSlice.actions

export const selectCount = (state: RootState) => state.info

export default commonSlice.reducer
