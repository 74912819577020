import React, { FC } from "react"
import { useIntl } from "react-intl"
import { Row, Col } from 'antd'
import Information from "@/view/account/Information"
import AccountSet from "@/view/account/accountSet"
import LegalEntities from "@/view/account/legalEntities"
import ValidationList from "@/view/account/validationList"
import { useAppSelector } from "@/store/hook"

const Account: FC = () => {

    const { infoDetail } = useAppSelector(state => state.info)

    const { messages } = useIntl()

    return (
        <div>
            <header className={ `page-title clearfix` }>
                <span className={ 'fl' }>{ messages['My_Account'] }</span>
                <div className={ 'fr customer-id-wrap' }>
                    <span className={ 'customer-id-title' }>{ messages['Customer_Id'] }:</span>
                    <span className={ 'font-size-16' }>{ infoDetail?.user_id }</span>
                </div>
            </header>
            <div>
                <ValidationList/>
                <div className={ 'mar-b-32' }>
                    <Information/>
                </div>
                <Row gutter={ 30 }>
                    <Col lg={ 12 } span={ 24 }>
                        <div className={ 'mar-b-32' }>
                            <AccountSet/>
                        </div>
                    </Col>
                    <Col lg={ 12 } span={ 24 }>
                        <div className={ 'mar-b-32' }>
                            <LegalEntities/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Account
