import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import BackBtn from "@/component/common/backBtn"
import StepOne from "@/view/questionnaire/stepOne"
import StepTwo from "@/view/questionnaire/stepTwo"
import { useAppSelector } from "@/store/hook"
import { useNavigate } from "react-router-dom"

const Questionnaire: FC = () => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { passportQuesList } = useAppSelector(state => state.info)

    const [step, setStep] = useState<number>(1)

    useEffect(() => {
        if (passportQuesList?.length) {
            navigate(-1)
        }
    }, [passportQuesList])

    return (
        <div>
            <header className={ 'page-title' }>
                <span>{ messages['Investor_Questionnaire'] }</span>
                <BackBtn title={ 'Back_to_My_Account' } backPath={ '/app/account' }/>
            </header>
            <div>
               <div style={{ display: step === 1 ? 'block' : 'none' }}>
                   <StepOne setStep={ setStep }/>
               </div>
                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                    <StepTwo setStep={ setStep }/>
                </div>
            </div>
        </div>
    )
}

export default Questionnaire
