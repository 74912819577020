import infoReducer from "./reducers/infoSlice"
import walletReducer from './reducers/walletSlice'
import commonReducer from './reducers/commonSlice'

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: {
        info: infoReducer,
        wallet: walletReducer,
        common: commonReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
