import React, { FC, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import Cards from "@/component/common/card"
import { Descriptions, message, Modal } from "antd"
import LegalForm from "@/component/account/legalEntities/legalForm"
import { RefFun } from "@/type"
import { GetLegalEntity, UpdateLegal } from "@/request/api"
import { useAppSelector } from "@/store/hook"
import useCountry from "@/hook/useCountry"

const LegalEntities: FC = () => {

    const { messages } = useIntl()

    const legalFormRef = useRef<RefFun>()

    const { fetchCountry } = useCountry()

    const { currentLang } = useAppSelector(state => state.common) // 当前语言

    const [openLegalForm, setOpenLegalForm] = useState<boolean>(false)

    const [record, setRecord] = useState<any>()

    const [loading, setLoading] = useState<boolean>(false)

    const editClick = () => {
        setOpenLegalForm(true)
    }

    const formHandle = (type: 'confirm' | 'cancel') => {
        switch (type) {
            case "confirm":
                legalFormRef?.current?.getValue()
                break
            case "cancel":
                //legalFormRef?.current?.reset()
                setOpenLegalForm(false)
        }
    }

    const legalFormSubmit = (values: any) => {

        setLoading(true)

        const callback = () => {
            message.success(messages['submitted_successfully'])
            getLegalEntity()
            setLoading(false)
            setOpenLegalForm(false)
            legalFormRef?.current?.reset()
        }

        UpdateLegal(values).then(() => {
            callback()
        }).catch(() => setLoading(false))
    }

    const getLegalEntity = () => {
        GetLegalEntity().then((res: any) => {
            setRecord(res?.data)
        })
    }

    useEffect(() => {
        getLegalEntity()
    }, [currentLang])

    return (
        <div>
            <Cards title={ 'Legal_Entities' } btnText={ record ? 'Edit' : 'Add' } editClick={ editClick }>
                {
                    record?.name ?
                        <Descriptions className={ 'M-des' } layout="vertical">
                            <Descriptions.Item span={ 3 } label={ messages['COMPANY'] }>{ record?.name }</Descriptions.Item>
                            <Descriptions.Item span={ 3 } label={ messages['COUNTRY_IN_CORPORATION'] }>{ fetchCountry(record?.country_id)?.title }</Descriptions.Item>
                        </Descriptions>
                        : <div style={ { padding: 50 } }/>
                }
            </Cards>
            {/*Add Legal Entities*/ }
            <Modal
                className={ 'M-modal' }
                title={  record ? messages['Edit_Legal_Entities'] : messages['Add_Legal_Entities'] }
                open={ openLegalForm }
                onCancel={ () => formHandle('cancel') }
                okText={ messages['Save'] }
                onOk={ () => formHandle('confirm') }
                confirmLoading={ loading }
            >
                <LegalForm ref={ legalFormRef } callback={ legalFormSubmit } record={ record }/>
            </Modal>
        </div>
    )
}

export default LegalEntities
