import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import ResetPassStepOne from "@/view/login/resetPass/stepOne"
import ResetPassStepTwo from "@/view/login/resetPass/stepTwo"
import { CheckCaptcha, ResetPassword } from "@/request/api"
import { message } from "antd"
import { useIntl } from "react-intl"

const ResetPass: FC = () => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const [step, setStep] = useState<number>(1)
    const [stepOneData, setStepOneData] = useState<any>()

    const [loading, setLoading] = useState<boolean>(false)

    const callback = (steps: 1 | 2, values?: any) => {
        setStep(steps)
        switch (steps) {
            case 1:
                setLoading(true)
                setStepOneData(values)
                CheckCaptcha({...values, category: 'password-reset'}).then((res: any) => {
                    if (!res?.data) {
                        message.error(messages['code_error'])
                    } else {
                        setStep(2)
                    }
                    setLoading(false)
                }).catch(() => setLoading(false))
                break
            case 2:
                ResetPassword({
                    ...stepOneData,
                    ...values
                }).then(() => {
                    message.success(messages['submitted_successfully'])
                    setLoading(false)
                    navigate('/login')
                }).catch(() => setLoading(false))
                break
        }
    }

    return (
        <div>
            <div className={ step === 1 ? 'show' : 'hide' }>
                <ResetPassStepOne callback={ callback } loading={ loading }/>
            </div>
            <div className={ step === 2 ? 'show' : 'hide' }>
                <ResetPassStepTwo callback={ callback } loading={ loading }/>
            </div>
        </div>
    )
}

export default ResetPass
