import React, { FC, useRef, useState } from "react"
import { useIntl } from "react-intl"
import Cards from "@/component/common/card"
import ChangeEmail from "@/component/account/accountSet/changeEmail"
import ChangePassword from "@/component/account/accountSet/changePassword"
import ChangeLanguage from "@/component/account/accountSet/changeLanguage"
import { message, Modal } from "antd"
import { RefFun } from "@/type"
import { useAppDispatch, useAppSelector } from "@/store/hook"
import { changeLang } from '@/store/reducers/commonSlice'
import { UpdateLang, UpdateEmail, UpdatePassword } from "@/request/api"
import useLangTitle from "@/hook/useLangTitle"
import { getInfo } from "@/store/reducers/infoSlice"

type formName = 'email' | 'password' | 'language'

const AccountSet: FC = () => {

    const { messages } = useIntl()

    const dispatch = useAppDispatch()

    const { fetchLangTitle } = useLangTitle()

    const { info } = useAppSelector(state => state.info)

    const [loading, setLoading] = useState<boolean>(false)

    const [openEmailForm, setOpenEmailForm] = useState<boolean>(false)
    const [openPasswordForm, setOpenPasswordForm] = useState<boolean>(false)
    const [openLanguageForm, setOpenLanguageForm] = useState<boolean>(false)

    const emailFormRef = useRef<RefFun>()
    const passwordFormRef = useRef<RefFun>()
    const languageFormRef = useRef<RefFun>()

    const editClick = (type: formName) => {
        switch (type) {
            case "email":
                setOpenEmailForm(true)
                break
            case "password":
                setOpenPasswordForm(true)
                break
            case "language":
                setOpenLanguageForm(true)
                break
        }
    }

    const formHandle = (formName: formName, refs: any, type: 'confirm' | 'cancel') => {
        const operaFn = (setOpen: Function) => {
            switch (type) {
                case "confirm":
                    refs?.current?.getValue()
                    break
                case "cancel":
                    setOpen()
                    if (formName !== 'language') {
                        refs?.current?.reset()
                    }
            }
        }
        switch (formName) {
            case "email":
                operaFn(() => setOpenEmailForm(false))
                break
            case "password":
                operaFn(() => setOpenPasswordForm(false))
                break
            case "language":
                operaFn(() => setOpenLanguageForm(false))
        }
    }

    const emailSubmit = (values: any) => {
        setLoading(true)
        UpdateEmail(values).then(() => {
            dispatch(getInfo())
            message.success(messages['submitted_successfully'])
            setLoading(false)
            formHandle('email', emailFormRef, 'cancel')
        }).catch(() => setLoading(false))
    }

    const passwordSubmit = (values: any) => {
        setLoading(true)
        UpdatePassword(values).then(() => {
            message.success(messages['submitted_successfully'])
            setLoading(false)
            formHandle('password', passwordFormRef, 'cancel')
        }).catch(() => setLoading(false))
    }

    const languageSubmit = (values: any) => {
        const { locale } = values
        setLoading(true)
        UpdateLang(values).then(_res => {
            message.success(messages['submitted_successfully'])
            setLoading(false)
            dispatch(changeLang(locale))
            setOpenLanguageForm(false)
            window.location.reload()
            // dispatch(getInfo())
        }).catch(() => setLoading(false))
    }

    return (
        <div>
            <Cards title={ 'Account_Settings' } btnText={ false }>
                <ul className={ 'des-list' }>
                    <li className={ 'clearfix' }>
                        <div className={ 'fl' }>
                            <span className={ 'title' }>{ messages['EMAIL'] }</span>
                            <span className={ 'text' }>{ info?.email }</span>
                        </div>
                        <span
                            onClick={ () => editClick('email') }
                            className={ 'fr edit top-12' }
                        >
                            { messages['Edit'] }
                        </span>
                    </li>

                    <li className={ 'clearfix' }>
                        <div className={ 'fl' }>
                            <span className={ 'title' }>{ messages['PASSWORD'] }</span>
                            <span className={ 'text' }>●●●●●●●●●</span>
                        </div>
                        <span
                            onClick={ () => editClick('password') }
                            className={ 'fr edit top-12' }
                        >
                            { messages['Edit'] }</span>
                    </li>

                    <li className={ 'clearfix' }>
                        <div className={ 'fl' }>
                            <span className={ 'title' }>{ messages['LANGUAGE'] }</span>
                            <span className={ 'text' }>{ fetchLangTitle(info?.locale) }</span>
                        </div>
                        <span
                            onClick={ () => editClick('language') }
                            className={ 'fr edit top-12' }
                        >
                            { messages['Edit'] }
                        </span>
                    </li>
                </ul>
            </Cards>
            {/*Change Email*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Change_Email'] }
                open={ openEmailForm }
                onCancel={ () => formHandle('email', emailFormRef, 'cancel') }
                okText={ messages['Check'] }
                onOk={ () => formHandle('email', emailFormRef, 'confirm') }
                confirmLoading={ loading }
            >
                <ChangeEmail ref={ emailFormRef } callback={ emailSubmit }/>
            </Modal>
            {/*Change Password*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Change_Password'] }
                open={ openPasswordForm }
                onCancel={ () => formHandle('password', passwordFormRef, 'cancel') }
                okText={ messages['Save'] }
                onOk={ () => formHandle('password', passwordFormRef, 'confirm') }
                confirmLoading={ loading }
            >
                <ChangePassword ref={ passwordFormRef } callback={ passwordSubmit }/>
            </Modal>
            {/*Change Lang*/ }
            <Modal
                className={ 'M-modal' }
                title={ messages['Change_Language'] }
                open={ openLanguageForm }
                onCancel={ () => formHandle('language', languageFormRef, 'cancel') }
                okText={ messages['Save'] }
                onOk={ () => formHandle('language', languageFormRef, 'confirm') }
                confirmLoading={ loading }
            >
                <ChangeLanguage ref={ languageFormRef } callback={ languageSubmit }/>
            </Modal>
        </div>
    )
}

export default AccountSet
