import enText from './json/en.json'
import enUS from 'antd/lib/locale-provider/en_US'

const en = {
    messages: {
        ...enText
    },
    antd: enUS,
    locale: 'en'
}
export default en
