import { LoadingOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import Style from '@/style/componet/upload.module.less'
import { UploadAddress } from "@/request/api"
import { useNavigate } from "react-router-dom"
import { website } from "@/config"

interface UploadType {
    category?: string,
    callback?: Function,
    image?: string
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
}

const beforeUpload = (file: RcFile) => {
    console.log(file.type)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf'
    if (!isJpgOrPng) {
        message.error('You can only upload GIF/JPG/PNG/PDF file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
}

const UploadAntd: React.FC<UploadType> = (props) => {

    const { category, callback, image } = props

    const navigate = useNavigate()

    const { messages } = useIntl()

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState<string>()

    const picHeaders = {
        Authorization: `${ window.sessionStorage.getItem('token') }`,
        // 'x-api-agent': '32db75a0a1eb11e89f4fcae7d8f1e761'
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return
        }

        if (info.file.status === 'error') {
            message.error(info?.file?.response?.message)
            if (info?.file?.response?.code === '401' || info?.file?.response?.code === 401) {
                window.localStorage.removeItem('token')
                navigate('/login')
                return
            }
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, url => {
                callback && callback(info?.file?.response?.data?.uuid)
                setLoading(false)
                setImageUrl(url)
            })
        }
    }

    const uploadButton = (
        <div>
            { loading ? <LoadingOutlined/> : <img src={ require('@/static/img/upload.png').default } alt="upload"/> }
            <div
                className={ 'font-size-16 font-weight-500' }
                style={ { marginTop: 8 } }
            >
                { messages['Select_and_upload_files'] }
            </div>
        </div>
    )

    useEffect(() => {
        if (image) {
            setImageUrl(`${ website }/attachments/${ image }`)
            callback && callback(image)
        }
    }, [image])

    return (
        <Upload
            name="file"
            listType="picture-card"
            className="M-upload"
            showUploadList={ false }
            headers={ picHeaders }
            action={ UploadAddress }
            beforeUpload={ beforeUpload }
            onChange={ handleChange }
            data={ { category: category } }
        >
            {
                imageUrl ?
                <img className={ Style.imgPreview } src={ imageUrl } alt="avatar"/>
                : uploadButton
            }
        </Upload>
    )
}

export default UploadAntd

UploadAntd.defaultProps = {
    category: 'image'
}
