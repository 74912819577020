import React, { ForwardRefRenderFunction, ReactNode, useImperativeHandle, forwardRef, useState } from "react"
import { useIntl } from "react-intl"
import { Form, Input } from "antd"
import { regular } from "@/tool/config"
import ErrorMessage from "@/component/common/errorMessage";

interface ChangePasswordFormType {
    callback?: Function,
}

const ChangePassword: ForwardRefRenderFunction<ReactNode, ChangePasswordFormType> = (props, ref) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback } = props

    const [password, setPassword] = useState<string>('')

    const passChange = (e: any) => {
        setPassword(e.target.value)
    }

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback(values)
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >

            <Form.Item
                label={ messages['Current_Password'] }
                name='password_original'
                rules={ [
                    { required: true }
                    /*{ pattern: regular?.password, message: messages['password_rule_tip'] as string }*/
                ] }
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                label={ messages['new_password'] }
                name='password'
                rules={ [
                    { required: true },
                    { pattern: regular?.password, message: '' }
                ] }
            >
                <Input.Password onChange={ passChange }/>
            </Form.Item>

            <Form.Item
                label={ messages['confirm_password'] }
                name='password_confirm'
                rules={ [
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(messages['passwords_not_match'] as string))
                        },
                    }),
                ] }
            >
                <Input.Password/>
            </Form.Item>
            <ErrorMessage text={ password }/>
            <Form.Item
               className={'no-border-b'}
            >
                <p>{ messages['password_tip'] }</p>
            </Form.Item>
        </Form>
    )
}

export default forwardRef(ChangePassword)
