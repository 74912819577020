import React, { FC, useState } from "react"
import Style from '@/style/componet/navbar.module.less'
import routes, { routeType } from "@/router"
import { Col, Row, Dropdown, Space, Drawer, Modal } from 'antd'
import { useIntl } from "react-intl"
import { useNavigate, useLocation } from "react-router-dom"
import { DownOutlined, MenuOutlined } from "@ant-design/icons"
import type { MenuProps } from 'antd'
import MobileMenu from "@/component/common/mobileMenu"
import Schedule from "@/component/schedule"
import { LogoutPassport } from "@/request/api"
import { useAppSelector } from "@/store/hook"

export const menuFilter = (routeList: routeType[]) => {
    return routeList.filter((item: any) => {
        return item.hide === false
    })
}

const Navbar: FC = () => {

    const { pathname } = useLocation()

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { infoDetail } = useAppSelector(state => state.info)

    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false)

    const [openSchedule, setOpenSchedule] = useState<boolean>(false)

    const items: MenuProps['items'] = [
        {
            key: '/app/account',
            label: (
                <div className={ Style.dropdownItem }>
                    <img src={ require('@/static/img/account-circle-outline.png').default } alt="user"/>
                    <span>{ messages['My_Account'] }</span>
                </div>
            ),
        },
        {
            key: '/app/documents',
            label: (
                <div className={ Style.dropdownItem }>
                    <img src={ require('@/static/img/text-box-outline.png').default } alt="user"/>
                    <span>{ messages['Documents'] }</span>
                </div>
            )
        },
        {
            type: 'divider',
        },
        {
            key: 'sign_out',
            label: (
                <div className={ Style.dropdownItem }>
                    <img src={ require('@/static/img/logout.png').default } alt="logout"/>
                    <span>{ messages['Sign_Out'] }</span>
                </div>
            )
        }
    ]

    const onClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'sign_out':
                navigate('/login')
                LogoutPassport().then(() => {
                    window.sessionStorage.removeItem('token')
                    navigate('/login')
                })
                return
            default:
                navigate(key)
        }
    }

    return (
        <div className={ `${ Style.navbar } container` }>
            <Row>
                {/*logo*/ }
                <Col lg={ 4 } span={ 12 }>
                    <div className={ Style.logo }>
                        <img className={ 'logo_icon' } src={ require('@/static/img/logo.png').default } alt="LOGO"/>
                    </div>
                </Col>
                {/*电脑端中间菜单*/ }
                <Col lg={ 8 } span={ 0 }>
                    <ul className={ Style.menu }>
                        {
                            menuFilter(routes).map((item: routeType) =>
                                <li
                                    key={ item?.key }
                                    className={ pathname === item?.key ? Style.active : '' }
                                    onClick={ () => navigate(item?.key as string) }
                                >
                                    { messages[item?.label] }
                                </li>)
                        }
                    </ul>
                </Col>

                <Col lg={ 12 } span={ 12 }>
                    <Row>
                        <Col lg={ 24 } span={ 0 }>
                            {/*电脑端右边下拉菜单*/ }
                            <div className={ 'clearfix' }>
                                <div className={ `${ Style.dropdown } fr` }>
                                    <Dropdown
                                        overlayClassName={ 'M-dropdown-navbar' }
                                        menu={ { items, onClick } } trigger={ ['click'] }
                                        placement="bottomRight"
                                    >
                                        <Space className={ 'text-color link' }>
                                            <span className={ 'font-size-16' }>{ infoDetail?.name }</span>
                                            <DownOutlined/>
                                        </Space>
                                    </Dropdown>
                                </div>
                                <div className={ `user-icon fr` }>
                                    <img src={ require('@/static/img/user-solid.png').default } alt="icon"/>
                                </div>
                                <div onClick={ () => setOpenSchedule(true) } className={ `${ Style.call } fr` }>
                                    <img src={ require('@/static/img/phone-dial.png').default } alt="call"/>
                                    <span>{ messages['schedule_a_call'] }</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={ 0 } span={ 24 } className={ 'clearfix' }>
                            {/*移动端菜单icon*/ }
                            <div onClick={ () => setOpenMobileMenu(true) }
                                 className={ `text-right fr ${ Style.mobile_menu_icon }` }>
                                <MenuOutlined style={ { fontSize: 18, color: '#777' } }/>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*移动端菜单Drawer*/ }
            <Drawer
                placement="right"
                onClose={ () => setOpenMobileMenu(false) }
                open={ openMobileMenu }
                closable={ false }
                className={ 'M-mobile-menu-drawer' }
                width={ '100%' }
            >
                <MobileMenu setOpenMobileMenu={ setOpenMobileMenu }/>
            </Drawer>
            {/*Schedule call*/ }
            <Modal
                open={ openSchedule }
                footer={ false }
                className={ 'M-modal' }
                width={ 1000 }
                onCancel={ () => setOpenSchedule(false) }
            >
                <Schedule/>
            </Modal>
        </div>
    )
}

export default Navbar
