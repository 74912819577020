import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import RegisterStepOne, { RegisterStepOneData } from "@/view/login/register/stepOne"
import RegisterStepTwo, { RegisterStepTwoData } from "@/view/login/register/stepTwo"
import RegisterStepThree, { RegisterThreeData } from "@/view/login/register/stepThree"
import useParamsLang from '@/hook/useParamsLang'
import { CheckCaptcha, LoginPassport, RegisterPassport, CheckEmail, GetInfo } from "@/request/api"
import { message } from "antd";
import { useIntl } from "react-intl"
import { changeLang } from "@/store/reducers/commonSlice"
import { useAppDispatch } from "@/store/hook"

const Register: FC = () => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const { paramsChangeLang } = useParamsLang()

    const [loading, setLoading] = useState<boolean>(false)

    const [step, setStep] = useState<number>(1)

    const [stepOneData, setStepOneData] = useState<any>()
    const [stepTwoData, setStepTwoData] = useState<any>()

    const stepOneCallback = (data: RegisterStepOneData) => {
        setStepOneData(data)
        setStep(2)
    }

    const stepTwoCallback = (data: RegisterStepTwoData) => {
        const { email, captcha } = data
        setLoading(true)
        CheckEmail({ //检测邮箱
            email
        }).then((res: any) => {
            console.log(res)
            if (res?.data) {
                message.error(messages['Email_is_registered'])
            } else {
                checkCaptcha()
            }
            setLoading(false)
        })

        const checkCaptcha = () => { //检测验证码
            CheckCaptcha({ recipient: email, captcha, category: 'register'}).then((res: any) => {
                if (!res?.data) {
                    message.error(messages['code_error'])
                } else {
                    setStepTwoData(data)
                    setStep(3)
                }
                setLoading(false)
            }).catch(() => setLoading(false))
        }

    }

    const stepTreeCallback = (data: RegisterThreeData) => {
        if (stepOneData && stepTwoData && data) {
            const { first_name, last_name, mobile, mobile_prefix } = stepOneData
            const { email, captcha } = stepTwoData
            const { password, password_confirm } = data

            setLoading(true)
            RegisterPassport({
                name: `${ first_name } ${ last_name }`,
                mobile,
                mobile_prefix,
                email,
                captcha,
                password,
                password_confirm
            }).then((res: any) => {
                message.success(messages['submitted_successfully'])
                setLoading(false)
                //登录
                LoginPassport({ username: email, password }).then((res: any) => {
                    sessionStorage.setItem('token', res.data.type + ' ' + res.data.token)
                    getInfo()
                    setLoading(false)
                    navigate('/app/verification')
                }).catch(() => setLoading(false))
            }).catch(() => setLoading(false))
        }
    }

    const getInfo = () => {
        GetInfo().then((res: any) => {
            if (res?.data?.locale) {
                dispatch(changeLang(res?.data?.locale))
            }
        })
    }

    useEffect(() => {
        paramsChangeLang()
    }, [])

    return (
        <div>
            <div className={ step === 1 ? 'show' : 'hide' }>
                <RegisterStepOne callback={ stepOneCallback }/>
            </div>
            <div className={ step === 2 ? 'show' : 'hide' }>
                <RegisterStepTwo callback={ stepTwoCallback } setStep={ setStep } loading={ loading }/>
            </div>
            <div className={ step === 3 ? 'show' : 'hide' }>
                <RegisterStepThree callback={ stepTreeCallback } setStep={ setStep } loading={ loading }/>
            </div>
        </div>
    )
}

export default Register
