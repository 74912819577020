import { getIdent, getIdentProgress, getInfo, getInfoDetail, getPassportQuesList, getQualification } from "@/store/reducers/infoSlice"
import { useAppDispatch } from "@/store/hook"

const useInfo = () => {

    const dispatch = useAppDispatch()

    //获取所有个人信息
    const fetchInfo = () => {
        dispatch(getInfo())
        dispatch(getInfoDetail())
        dispatch(getIdent())
    }

    //获取个人认证信息进度、资质证明、问卷调查
    const fetchProgress = () => {
        dispatch(getIdentProgress())
        dispatch(getQualification())
        dispatch(getPassportQuesList())
    }

    //获取认证进度、个人资质证明、问卷调查等是否通过
    const fetchPassed = () => {
        return new Promise((resolve, reject) => {
            const promiseHandle = (method: Function) => {
                return new Promise((resolve_, reject_) => {
                    dispatch((method())).then((res: any) => {
                        resolve_(res)
                    }).catch(() => reject_())
                })
            }

            const identProgress = promiseHandle(getIdentProgress)
            const qualification = promiseHandle(getQualification)
            const passportQuesList = promiseHandle(getPassportQuesList)
            Promise.all([identProgress, qualification, passportQuesList])
                .then((res: any) => {
                    if (res?.length && res[0]?.payload?.data === "Completed" && res[1]?.payload?.data && res[2]?.payload?.data?.length) {
                        resolve(true) //全部通过
                    } else {
                        resolve(false)
                    }
                }).catch((err) => reject(err))
        })
    }

    return {
        fetchInfo, fetchProgress, fetchPassed
    }
}

export default useInfo
