// 钱包
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
//import { GetWallets, AddWallet } from "@/request/api"

interface CounterState {
    walletList: any[],
    account: string
}

const initialState: CounterState = {
    walletList: [],
    account: ''
}

export const getWalletsAsync = createAsyncThunk( // 获取钱包列表
    'wallets/getWallets',
    async () => {
       // return await GetWallets()
    }
)

export const addWalletAsync = createAsyncThunk( // 增加钱包
    'wallets/addWallet',
    async (wallet: string) => {
        //return await AddWallet({ address: wallet })
    }
)

export const infoSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        // action传参
        accountChange: (state, action: PayloadAction<any>) => {
            state.account = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWalletsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.walletList = action.payload.data
            })
    },
})

// export const { increment, decrement, incrementByAmount } = infoSlice.actions

export const { accountChange } = infoSlice.actions

export const selectCount = (state: RootState) => state.info

export default infoSlice.reducer
