import React, { FC, useState, useEffect } from "react"
import BackBtn from "@/component/common/backBtn"
import { useParams } from "react-router-dom"
import Style from '@/style/view/newsDetail.module.less'
import { GetNewsDetail } from "@/request/api"
import moment from "moment"
import SpinCom from "@/component/common/SpinCom"

const NewsDetail: FC = () => {

    const params = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [detail, setDetail] = useState<any>()

    const getNewsDetail = (id: string) => {
        setLoading(true)
        GetNewsDetail(id).then((res: any) => {
            setLoading(false)
            setDetail(res?.data)
        }).catch(() => setLoading(false))
    }

    useEffect(() => {
        if (params?.id) {
            getNewsDetail(params?.id)
        }
    }, [])

    return (
        <div>
            {
                loading ? <SpinCom/> :
                    <div className={ Style.newsDetail }>
                        <header className={ 'news-detail-header' }>
                            <BackBtn title={ 'Back_to_News_List' } backPath={ '/app/news' }/>
                        </header>
                        <div className={ `${ Style.main } max-width-800` }>
                            <div className={ `${ Style.title }` }>
                                { detail?.title }
                            </div>
                            <div className={ `${ Style.content }` }>
                                <header className={ `${ Style.sign }` }>
                                    <span
                                        className={ Style.date }>{ moment.unix(detail?.created_at).format('YYYY-MM-DD HH:mm') }</span>
                                    <span className={ Style.posted }>{ detail?.contributor }</span>
                                </header>
                                <div className={ Style.text } dangerouslySetInnerHTML={ { __html: detail?.content } }>

                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default NewsDetail
