import React, { FC, useState } from "react"
import { useIntl } from "react-intl"
import Style from '@/style/view/login.module.less'
import LoginHeader from "@/component/login/header"
import { Button, Form, Input } from "antd"
import GetCodeButton from "@/component/common/getCodeButton"
import { useNavigate } from "react-router-dom"

interface ResetPassStepOneType {
    callback: Function
    loading?: boolean
}

const ResetPassStepOne: FC<ResetPassStepOneType> = (props) => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback, loading } = props

    const [email, setEmail] = useState<string>('')

    const emailChange = (e: any) => {
        setEmail(e?.target?.value)
    }

    const next = () => {
        form.validateFields().then((values: any) => {
            callback && callback(1, values)
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader title={ 'reset_password' }/>
                <div className={ `${ Style.resetTips }` }>{ messages['reset_tip'] }</div>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >
                        <Form.Item
                            label={ messages['email_address'] }
                            name={ 'recipient' }
                            rules={ [
                                {
                                    required: true,
                                },
                                {
                                    type: 'email',
                                    message: messages['not_valid_email'] as string,
                                }
                            ] }
                        >
                            <Input onChange={ emailChange }/>
                        </Form.Item>
                        <div className={ 'getCodeItem' }>
                            <Form.Item
                                label={ messages['verification_code'] }
                                name={ 'captcha' }
                                rules={ [{ required: true }] }
                            >
                                <Input placeholder={ messages['please_enter_verification'] as string }/>
                            </Form.Item>
                            <div className={'getCodeButton'}>
                                <GetCodeButton address={ email } type={ 'email' } category={ 'password-reset' }/>
                            </div>
                        </div>
                        <div className={ 'M-btn-warp' }>
                            <Button
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                                loading={ loading }
                            >
                                { messages['verify'] }
                            </Button>
                        </div>
                       {/*backWrap*/}
                    </Form>
                </div>
            </div>
            <div className={ Style.loginTipWrap }>
                <p onClick={ () => navigate('/login') }
                   className={ `${ Style.tips } mar-b-10 pointer text-primary` }>
                    { messages['Return_to_login'] }
                </p>
            </div>
        </div>
    )
}

export default ResetPassStepOne
