import React, { FC } from "react"
import { Button } from "antd"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Navbar from "@/component/common/navbar"

const Home: FC = () => {

    const { messages } = useIntl()

    const navigate = useNavigate()

    const pushRouter = (path: string) => {
        navigate(path)
    }

    return (
        <div>
            <Navbar/>
            <Button onClick={ () => pushRouter('/login') }>{ messages['login'] }</Button>
        </div>
    )
}

export default Home
