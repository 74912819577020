import React, { ReactNode, ForwardRefRenderFunction, useImperativeHandle, forwardRef, useEffect } from "react"
import { Form, Select, Row, Col, Input } from "antd"
import { useIntl } from "react-intl"
import { dateList } from "@/tool"
import { useAppSelector } from "@/store/hook"

export interface FormComType {
    callback?: Function,
    record?: any,
    showFullName?: boolean
}

const { Option } = Select

const InfoForm: ForwardRefRenderFunction<ReactNode, FormComType> = (props, ref) => {

    const { callback, showFullName } = props

    const { infoDetail } = useAppSelector(state => state.info)

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { country } = useAppSelector(state => state.common)

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback(values)
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    useEffect(() => {
        if (infoDetail) { // identProgress === 'Completed' &&
            const { birthday } = infoDetail
            let year, month, day = null
            if (birthday) {
                year = birthday.split('-')[0]
                month = birthday.split('-')[1]
                day = birthday.split('-')[2]
            }

            if (showFullName) {
                form.setFieldsValue({
                    name: infoDetail?.name,
                    year,
                    month,
                    day,
                    country_id: infoDetail?.country_id || null,
                    postcode: infoDetail?.postcode,
                    address: infoDetail?.address,
                    tax_residence: infoDetail?.tax_residence || null,
                    tax_id: infoDetail?.tax_id
                })
            }else {
                form.setFieldsValue({
                    first_name: infoDetail?.name.split(' ')[0] || null,
                    last_name: infoDetail?.name.split(' ')[1] || null,
                    year,
                    month,
                    day,
                    country_id: infoDetail?.country_id || null,
                    postcode: infoDetail?.postcode,
                    address: infoDetail?.address,
                    tax_residence: infoDetail?.tax_residence || null,
                    tax_id: infoDetail?.tax_id
                })
            }
        }
    },[infoDetail])

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >

            {
                showFullName ?
                    <Form.Item
                        label={ messages['FULL_NAME'] }
                        name='name'
                        rules={ [
                            { required: true }
                        ] }
                    >
                        <Input/>
                    </Form.Item> :
                    <Row gutter={ 35 }>
                        <Col span={ 12 }>
                            <Form.Item
                                label={ messages['first_name'] }
                                name='first_name'
                                rules={ [
                                    { required: true }
                                ] }
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={ 12 }>
                            <Form.Item
                                label={ messages['last_name'] }
                                name='last_name'
                                rules={ [
                                    { required: true }
                                ] }
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
            }

            <Row gutter={ 16 }>
                <Col span={ 8 }>
                    <Form.Item
                        className={ 'capitalize-form-item' }
                        label={ messages['DAY_OF_BIRTH'] }
                        name='year'
                        rules={ [
                            { required: true }
                        ] }
                    >
                        <Select>
                            {
                                dateList("year").map(item => <Option value={ item } key={ item }>{ item }</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={ 8 }>
                    <Form.Item
                        label={ ' ' }
                        name='month'
                        rules={ [
                            { required: true }
                        ] }
                    >
                        <Select>
                            {
                                dateList("month").map(item => <Option value={ item } key={ item }>{ item }</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={ 8 }>
                    <Form.Item
                        label={ ' ' }
                        name='day'
                        rules={ [
                            { required: true }
                        ] }
                    >
                        <Select>
                            {
                                dateList("day").map(item => <Option value={ item } key={ item }>{ item }</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label={ messages['COUNTRY'] }
                name='country_id'
                rules={ [
                    { required: true }
                ] }
            >
                <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {
                        country?.length ? country.map((item: any) =>
                            <Option key={ item?.id } value={ item?.id } label={ item?.title }>{ item?.title }</Option>
                        ) : null
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={ messages['POSTAL_CODE'] }
                name='postcode'
                rules={ [
                    { required: true }
                ] }
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={ messages['ADDRESS'] }
                name='address'
                rules={ [
                    { required: true }
                ] }
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={ messages['TAX_RESIDENCE'] }
                name='tax_residence'
                rules={ [
                    { required: true }
                ] }
            >
                <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {
                        country?.length ? country.map((item: any) =>
                            <Option key={ item?.id } value={ item?.id } label={ item?.title }>{ item?.title }</Option>
                        ) : null
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={ messages['TAX_ID'] }
                name='tax_id'
                rules={ [
                    { required: true }
                ] }
            >
                <Input/>
            </Form.Item>
        </Form>
    )
}

export default forwardRef(InfoForm)
