import React, { ForwardRefRenderFunction, ReactNode, useImperativeHandle, forwardRef, useState } from "react"
import { useIntl } from "react-intl"
import { Form, Input } from "antd"
import GetCodeButton from "@/component/common/getCodeButton"

interface ChangeEmailFormType {
    callback?: Function,
}

const ChangeEmail: ForwardRefRenderFunction<ReactNode, ChangeEmailFormType> = (props, ref) => {

    const { messages } = useIntl()

    const [form] = Form.useForm()

    const { callback } = props

    const [email, setEmail] = useState<string>('')

    const emailChange = (e: any) => {
        setEmail(e.target.value)
    }

    useImperativeHandle(ref, () => ({
            getValue: () => {
                form.validateFields().then((values: any) => {
                    callback && callback(values)
                })
            },
            reset: () => {
                form.resetFields()
            }
        })
    )

    return (
        <Form
            form={ form }
            name="basic"
            autoComplete="off"
            layout={ 'vertical' }
            className={ 'M-form' }
        >

            <Form.Item
                label={ messages['New_Email_Address'] }
                name='email'
                rules={ [
                    { required: true },
                    {
                        type: 'email',
                        message: messages['not_valid_email'] as string,
                    }
                ] }
            >
                <Input onChange={ emailChange }/>
            </Form.Item>
            <div className={ 'getCodeItem' }>
                <Form.Item
                    label={ messages['verification_code'] }
                    name={ 'captcha' }
                    rules={ [{ required: true }] }
                >
                    <Input placeholder={ messages['please_enter_verification'] as string }/>
                </Form.Item>
                <div className={ 'getCodeButton' }>
                    <GetCodeButton address={ email } type={ 'email' } category={ 'email-update' }/>
                </div>
            </div>
        </Form>
    )
}

export default forwardRef(ChangeEmail)
