import React, { FC } from "react"
import LoginHeader from "@/component/login/header"
import { Form, Input, Row, Col, Select, Button } from "antd"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Style from "@/style/view/login.module.less"
import { useAppSelector } from "@/store/hook"
import { regular } from "@/tool/config";

export interface RegisterStepType {
    callback?: Function
}

export interface RegisterStepOneData {
    first_name: string,
    last_name: string,
    mobile: string,
    mobile_prefix: string
}

const { Option } = Select

const RegisterStepOne: FC<RegisterStepType> = (props) => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { callback } = props

    const [form] = Form.useForm()

    const { country } = useAppSelector(state => state.common)

    const next = () => {
        form.validateFields().then((values: any) => {
            callback && callback(values)
        })
    }

    return (
        <div className={ Style.login }>
            <div className={ Style.loginBox }>
                <LoginHeader step={ 1 } title={ 'create_account' }/>
                <div>
                    <Form
                        form={ form }
                        name="basic"
                        autoComplete="off"
                        layout={ 'vertical' }
                        className={ 'M-form' }
                    >
                        <Row gutter={ 35 }>
                            <Col span={ 12 }>
                                <Form.Item
                                    label={ messages['first_name'] }
                                    name={ 'first_name' }

                                    rules={ [{ required: true }] }
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={ 12 }>
                                <Form.Item
                                    label={ messages['last_name'] }
                                    name={ 'last_name' }

                                    rules={ [{ required: true }] }
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ 35 }>
                            <Col span={ 12 } lg={ 8 }>
                                <Form.Item
                                    label={ messages['country_code'] }
                                    name={ 'mobile_prefix' }
                                    rules={ [{ required: true }] }
                                >
                                    <Select
                                        dropdownMatchSelectWidth={ false }
                                        allowClear
                                        showSearch
                                        optionLabelProp="value"
                                        filterOption={ (input, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            country?.length ?
                                                country.map((item: any) =>
                                                    <Option
                                                        label={ item?.title }
                                                        key={ item?.id }
                                                        value={ item?.mobile_prefix }
                                                    >
                                                        <span>{ item?.title }</span>
                                                        <span
                                                            style={ { marginLeft: 10 } }
                                                        >
                                                            +{ item?.mobile_prefix }
                                                        </span>
                                                    </Option>) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={ 12 } lg={ 16 }>
                                <Form.Item
                                    label={ messages['phone_number'] }
                                    name={ 'mobile' }
                                    rules={ [
                                        { required: true },
                                        {
                                            pattern: regular?.number,
                                            message: messages['Invalid_phone_number'] as string
                                        }
                                    ] }
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className={ 'M-btn-warp' }>
                            <Button
                                onClick={ () => next() }
                                className={ 'M-btn' }
                                style={ { width: '100%' } }
                                type='primary'
                            >
                                { messages['next'] }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <div className={ Style.loginTipWrap }>
                <p className={ `${ Style.tips_pa } ${ Style.tips }` }>
                    { messages['login_tip'] }
                    <span
                        onClick={ () => navigate('/login') }
                        className={ 'pointer text-primary' }>
                        { messages['log_in'] }
                    </span>
                </p>
            </div>
        </div>
    )
}


export default RegisterStepOne
