import React, { FC } from "react" // 注册完成跳转的验证页面
import ValidationList from "@/view/account/validationList"
import Style from '@/style/view/verification.module.less'
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "@/store/hook"

const Verification: FC = () => {

    const navigate = useNavigate()

    const { messages } = useIntl()

    const { infoDetail } = useAppSelector(state => state.info)

    return (
        <div className={ Style.verification }>
            <header>
                <span>Dear { infoDetail?.name },</span>
                <i>{ messages['verification_tip1'] }</i>
            </header>
            <div className={ Style.dec }>
                <p>{ messages['verification_tip2'] }</p>
                <p>{ messages['verification_tip3'] }</p>
            </div>
            <ValidationList/>
        </div>
    )
}

export default Verification
