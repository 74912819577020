//import NP from 'number-precision'
import { Lang } from '@/store/reducers/commonSlice'
import { agreementList, monthArr } from "@/tool/config"
import { officialWebsite } from "@/config"

export type agreementType = 'Contact' | 'Terms_of_Use' | 'Privacy_Policy' | 'Platform_Security'

export const agreementLink = (agreement: agreementType): string => {
    const lang: Lang = window.localStorage.getItem('lang') as Lang || 'en'
    let link = agreementList.filter((item) => item.title === agreement)[0].link[lang]
    return officialWebsite + link
}

export const langFilter = (lang?: string) => {
    let lang_ = lang || localStorage.getItem('lang') || 'en'
    switch (lang_) {
        case 'en':
            return 'en'
        case 'ja':
            return 'ja'
        case 'zh':
            return 'zh_CN'
        default:
            return 'en'
    }
}

export const monthFilter = (month: string) => {
    if (!month) {
        return false
    }
    return monthArr[(Number(month) - 1)]
}

export type stateType = "Approved" | "Completed" | "Close" | "Pending" | "Processing" | null

// 数组根据某项值进行排序
export const arrObjSort = (arr: any[], key: string) => {
    return arr.sort((a: any, b: any) => a[key].localeCompare(b[key]))
}


